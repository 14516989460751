import DeleteIcon from "@mui/icons-material/Delete";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import * as React from "react";
import { getDimensions } from "../RampCanvas/math";
import { Shape } from "../RampCanvas/RampCanvas";
import { useLocalRampState } from "../RampEditorPresenter";
import { Tool } from "./Toolbar";

type Props = {
  shapes: Shape[];
  setShapes: React.Dispatch<React.SetStateAction<Shape[]>>;
  tools: Tool[];
};

export const ShapeElements: React.FC<Props> = ({
  shapes,
  setShapes,
  tools,
}) => {
  const { setHighlightedShapes } = useLocalRampState();

  return (
    <Box width="100%">
      {shapes.length === 0 && (
        <Typography variant="subtitle2" align="center">
          No shapes added
        </Typography>
      )}
      {shapes.map((shape, index) => {
        const dimensions = getDimensions(shape.points);
        const formattedDimensions = `${dimensions.width.toFixed(
          0
        )} ft x ${dimensions.height.toFixed(0)} ft`;
        return (
          <Stack
            key={`shape-stack-${index}`}
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="center"
            width="100%"
            onMouseEnter={async () => {
              setHighlightedShapes([shape.id]);
            }}
            onMouseLeave={async () => {
              setHighlightedShapes([]);
            }}
          >
            {tools.find((t) => t.name === shape.type)?.icon}
            <Typography variant="subtitle2">{formattedDimensions}</Typography>
            <IconButton
              size="small"
              onClick={() => {
                setShapes(
                  shapes.map((s) => ({
                    ...s,
                    locked: s.id === shape.id ? !shape.locked : s.locked,
                  }))
                );
              }}
            >
              {shape.locked ? <LockIcon /> : <LockOpenIcon />}
            </IconButton>
            <IconButton
              size="small"
              onClick={() => {
                setShapes(shapes.filter((s) => s.id !== shape.id));
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        );
      })}
    </Box>
  );
};
