import {
  Autocomplete,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { uniq } from "lodash";
import * as React from "react";
import { useMultiHangarState } from "../../../containers/MultiHangarContainer";
import { HOLDING_AREA_ID } from "../../../screens/MultiHangar/HoldingArea";
import { Hangar, MovableObstacle, Tenant } from "../../../types";
import { uuidv4 } from "../../../utils";
import { FrozenGrid } from "../../FrozenGrid";
import { Layout } from "../../Layout/Layout";

export const FavoriteStackPresenter: React.FC<{
  setOpen: (open: boolean) => void;
  setConfirm: (confirm: {
    open: boolean;
    text: string | React.ReactNode;
    okText: string;
    onOk: () => void;
  }) => void;
  stackName: string;
}> = ({ setOpen, setConfirm, stackName }) => {
  const { hangars, setHangars } = useMultiHangarState();
  return (
    <Stack
      direction="column"
      spacing={1}
      justifyContent="center"
      sx={{ height: "100%" }}
    >
      <Typography textAlign="center" sx={{ width: "100%" }}>
        {stackName}
      </Typography>
      <Stack direction="row" spacing={1} justifyContent="center">
        <Button
          variant="contained"
          color="success"
          onClick={() => {
            setConfirm({
              open: true,
              text: (
                <Typography variant="inherit">
                  {`Remove all aircraft from selected hangar(s) and replace with aircraft for ‘${stackName}’.`}
                  <br />
                  <br />
                  {`Note: Aircraft in this saved stack will be moved from other hangars if needed.`}
                </Typography>
              ),
              okText: `Yes, reset`,
              onOk: () => {
                // deduplicates tenants and movable obstacles across hangars
                const entityAssignments = {};
                for (const hangar of hangars) {
                  const favorite = hangar.stacks.find(
                    (s) => s.name === stackName
                  );
                  if (!favorite) {
                    continue;
                  }
                  for (const tenant of favorite.tenants) {
                    if (entityAssignments[tenant.id]) {
                      continue;
                    }
                    entityAssignments[tenant.id] = hangar.id;
                  }
                  for (const movableObstacle of favorite.movableObstacles) {
                    if (entityAssignments[movableObstacle.id]) {
                      continue;
                    }
                    entityAssignments[movableObstacle.id] = hangar.id;
                  }
                }
                setHangars(
                  hangars.map((hangar) => {
                    const favorite = hangar.stacks.find(
                      (s) => s.name === stackName
                    );
                    const isInThisHangar = (e: Tenant | MovableObstacle) => {
                      if (entityAssignments[e.id]) {
                        return entityAssignments[e.id] === hangar.id;
                      }
                      return true;
                    };

                    if (!favorite) {
                      return {
                        ...hangar,
                        stack: {
                          ...hangar.stack,
                          tenants: hangar.stack.tenants.filter(isInThisHangar),
                          movableObstacles: hangar.stack.movableObstacles.filter(
                            isInThisHangar
                          ),
                        },
                      };
                    }

                    const tenants = favorite.tenants.filter(isInThisHangar);
                    const movableObstacles = favorite.movableObstacles.filter(
                      isInThisHangar
                    );

                    return {
                      ...hangar,
                      stack: {
                        ...hangar.stack,
                        tenants: tenants.map((t) => ({
                          ...t,
                          position: {
                            ...t.position,
                            id: uuidv4(),
                            stack_id: hangar.stack.id,
                            entity_id: t.entity_id,
                          },
                        })),
                        movableObstacles: movableObstacles.map((mo) => ({
                          ...mo,
                          position: {
                            ...mo.position,
                            id: uuidv4(),
                            stack_id: hangar.stack.id,
                            entity_id: mo.entity_id,
                          },
                        })),
                      },
                    };
                  })
                );
                setOpen(false);
              },
            });
          }}
        >
          Use
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            setConfirm({
              open: true,
              text: (
                <Typography variant="inherit">
                  {`You are about to delete '${stackName} from all selected hangars.`}
                  <br />
                  <br />
                  {`Meaning…that if '${stackName} is saved for hangars A, B, C…but Ive only selected A and B…then C still keeps it’s saved '${stackName}`}
                </Typography>
              ),
              okText: `Yes, delete this favorite`,
              onOk: () => {
                setHangars(
                  hangars.map((hangar) => ({
                    ...hangar,
                    stacks: hangar.stacks.filter((s) => s.name !== stackName),
                  }))
                );
              },
            });
          }}
        >
          Delete
        </Button>
      </Stack>
    </Stack>
  );
};

export const renderHangarStack = (hangar: Hangar, stackName: string) => {
  const stack = hangar.stacks.find((s) => s.name === stackName);
  if (!stack) {
    return (
      <Box sx={{ height: "154px", p: 2 }}>
        <Typography
          variant="caption"
          component="p"
          textAlign="center"
          alignItems="center"
          sx={{ width: "100%", mt: "75px" }}
        >
          No stack
        </Typography>
      </Box>
    );
  }
  return (
    <Stack direction="column" alignItems="center">
      <Layout
        stack={stack}
        setStack={() => {
          // not needed
        }}
        location={{ type: "hangar", ...hangar }}
        height={150}
        options={{
          hideUnplaced: true,
          readOnly: true,
          selectable: false,
          printable: false,
        }}
      />
    </Stack>
  );
};

type Props = {
  setOpen: (open: boolean) => void;
  setConfirm: (confirm: {
    open: boolean;
    text: string | React.ReactNode;
    okText: string;
    onOk: () => void;
  }) => void;
};

export const MultiHangarFavoritesPresenter: React.FC<Props> = ({
  setOpen,
  setConfirm,
}) => {
  const [stackFilter, setStackFilter] = React.useState<string>("");
  const { hangars: allHangars } = useMultiHangarState();
  const hangars = allHangars.filter((h) => h.id !== HOLDING_AREA_ID);
  const stackNames = uniq(hangars.flatMap((h) => h.stacks.map((s) => s.name)))
    .sort()
    .filter((name) => !["current", "reference"].includes(name))
    .filter((name) => {
      if (!stackFilter) {
        return true;
      }
      return (
        name.toLocaleLowerCase().indexOf(stackFilter.toLocaleLowerCase()) > -1
      );
    });

  const columns = [
    {
      id: "stackchooser",
      label: "",
      render: () => (
        <Autocomplete
          disablePortal
          size="small"
          onChange={(event: any, newValue: string | null) => {
            setStackFilter(newValue);
          }}
          options={stackNames}
          sx={{ width: "100%" }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Search for stack"
            />
          )}
        />
      ),
    },
    ...hangars.map((hangar) => ({ id: hangar.id, label: hangar.name })),
  ];
  const rows = stackNames.map((stackName) => ({
    id: stackName,
    render: (columnId: string, row: any) => {
      if (columnId === "stackchooser") {
        return (
          <Box height={200}>
            <FavoriteStackPresenter
              setOpen={setOpen}
              setConfirm={setConfirm}
              stackName={stackName}
            />
          </Box>
        );
      }
      const hangar = hangars.find((h) => h.id === columnId);
      return <Box height={200}>{renderHangarStack(hangar, stackName)}</Box>;
    },
  }));

  return (
    <Box sx={{ height: 600 }}>
      {rows.length === 0 ? (
        <Typography
          textAlign="center"
          alignItems="center"
          sx={{ width: "100%", mt: "75px" }}
        >
          No favorites
        </Typography>
      ) : (
        <FrozenGrid columns={columns} rows={rows} />
      )}
    </Box>
  );
};
