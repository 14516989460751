import { TextField, TextFieldProps, Typography } from "@mui/material";
import { useState } from "react";
import React = require("react");

type PercentageInputProps = TextFieldProps & {
  step?: number;
  initialValue: number;
  onChange?: (value: number) => void;
};

export const PercentageInput: React.FC<PercentageInputProps> = ({
  initialValue,
  step = 0.01,
  onChange,
  ...props
}) => {
  const [value, setValue] = useState<number | "">(initialValue);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    // Allow empty string (to clear input) or valid number
    if (inputValue === "" || !isNaN(Number(inputValue))) {
      setValue(inputValue === "" ? "" : Number(inputValue));
      onChange && onChange(Number(inputValue));
    }
  };

  const handleBlur = () => {
    // Round to 2 decimal places on blur
    if (value !== "" && typeof value === "number") {
      setValue(parseFloat(value.toFixed(2)));
    }
  };

  return (
    <TextField
      {...props}
      type="number"
      size="small"
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      InputProps={{
        ...props.InputProps,
        endAdornment: <Typography>%</Typography>,
        inputProps: {
          ...props.InputProps?.inputProps,
          step: step, // Add step for up/down arrows
          min: 0, // Optional: Prevent negative percentages
          max: 100, // Optional: Limit to 100%
        },
      }}
    />
  );
};
