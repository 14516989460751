import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import * as React from "react";
import { FBOEmployee } from "../../../types";
import { ConfirmationDialog } from "../../../widgets/ConfirmationDialog";

type Props = {
  users: FBOEmployee[];
  setFilter: (text: string) => void;
  onChangeRole: (id: string, value: string) => void;
  onDeleteUser: (id: string) => void;
};

export const UsersPresenter: React.FC<Props> = ({
  users,
  setFilter,
  onChangeRole,
  onDeleteUser,
}) => {
  const columns: GridColDef[] = [
    {
      field: "user_name",
      headerName: "Name",
      width: 175,
      editable: false,
      valueGetter: (_, row) => row.user_name || "---",
    },
    {
      field: "user_display_name",
      headerName: "Email",
      width: 250,
      editable: false,
      valueGetter: (_, row) => row.user_display_name || "anonymous",
    },
    {
      field: "_role",
      headerName: "Role",
      type: "singleSelect",
      valueOptions: ["manager", "operator"],
      width: 250,
      editable: true,
      renderCell: (params) => (
        <Stack direction="row" spacing={1} height="100%" alignItems="center">
          <TextField
            fullWidth
            size="small"
            select
            value={params.row._role}
            onChange={(evt) =>
              onChangeRole(params.row.user_id, evt.target.value)
            }
          >
            <MenuItem value="manager">manager</MenuItem>
            <MenuItem value="operator">operator</MenuItem>
          </TextField>
        </Stack>
      ),
    },
    {
      field: "action",
      headerName: "",
      sortable: false,
      renderCell: (params) => {
        const [confirmDelete, setConfirmDelete] = React.useState<boolean>(
          false
        );
        return (
          <>
            <Button
              size="small"
              startIcon={<DeleteIcon />}
              onClick={() => setConfirmDelete(true)}
            >
              Delete
            </Button>
            {confirmDelete && (
              <ConfirmationDialog
                title={"Delete User"}
                text={`Are you sure you want to delete ${params.row.user_display_name}?`}
                onCancel={() => {
                  setConfirmDelete(false);
                }}
                okText="Yes. Delete this user"
                onOk={() => {
                  onDeleteUser(params.row.user_id);
                }}
                onClose={() => {
                  setConfirmDelete(false);
                }}
              />
            )}
          </>
        );
      },
    },
  ];
  return (
    <Stack direction="column" spacing={2} sx={{ width: "100%", minWidth: 700 }}>
      <TextField
        fullWidth
        size="small"
        placeholder="Search by email"
        inputProps={{
          ["data-testid"]: "input_filter_team_member",
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={(evt) => setFilter(evt.target.value)}
      />
      <div style={{ height: 500, width: "100%" }}>
        <DataGrid
          rows={users}
          columns={columns}
          pageSizeOptions={[20]}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </div>
    </Stack>
  );
};
