import * as React from "react";
import { useIdentity } from "../../containers/IdentityContainer";

export const TawkIntegration = () => {
  const { airplxIdentity } = useIdentity();

  // wait for Tawk API to be available and set user attributes
  React.useEffect(() => {
    const intervalId = setInterval(() => {
      if (
        window.location.hostname !== "localhost" &&
        window.Tawk_API &&
        window.Tawk_API?.setAttributes &&
        airplxIdentity?.userid
      ) {
        window.Tawk_API?.setAttributes({
          hash: airplxIdentity?.userid,
          email: airplxIdentity?.email,
          name: airplxIdentity?.email,
        });
        clearInterval(intervalId); // Stop the interval once the function runs
      }
    }, 500); // Check every 500ms (adjust if necessary)

    // Cleanup interval on unmount
    return () => clearInterval(intervalId);
  }, [airplxIdentity]);
  return <></>;
};
