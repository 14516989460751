import AddIcon from "@mui/icons-material/Add";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { IconButton, MenuItem, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useActiveFBOs } from "../../../containers/ActiveFBOContainer";
import { useIdentity } from "../../../containers/IdentityContainer";
import { useInvitationsState } from "../../../containers/InvitationsStateContainer";
import {
  Props as ConfirmDialogProps,
  ConfirmationDialog,
} from "../../../widgets/ConfirmationDialog";

type Props = {
  fbo_id: string;
  onClose: () => void;
};
export const InviteDialog: React.FC<Props> = ({ fbo_id, onClose }) => {
  const { activeFBO } = useActiveFBOs();
  const { airplxIdentity } = useIdentity();
  const [confirm, setConfirm] = React.useState<ConfirmDialogProps>(null);
  const { makeInvite } = useInvitationsState();
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState<string>("");
  const [role, setRole] = React.useState<string>("operator");
  const [inviteURL, setInviteURL] = React.useState<string>("");

  React.useEffect(() => {
    setEmail("");
    setRole("operator");
    setInviteURL("");
  }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const displayInviteButton =
    (activeFBO.user_role === "manager" &&
      activeFBO?.settings?.addTeamMembersForManager) ||
    (activeFBO.user_role === "operator" &&
      activeFBO?.settings?.addTeamMembersForOperator) ||
    airplxIdentity.isadmin;

  return (
    <div>
      {displayInviteButton && (
        <Button
          variant="contained"
          color="success"
          onClick={handleClickOpen}
          startIcon={<AddIcon />}
        >
          Add Team Member
        </Button>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Invite</DialogTitle>
        <DialogContent>
          {Boolean(inviteURL) ? (
            <DialogContentText>
              There should be an email in {email}'s inbox with an invite. <br />
              <br />
              Alternatively, you can send the user this link directly:
              <Stack direction="row" alignItems="center">
                <Typography>{inviteURL}</Typography>
                <CopyToClipboard text={inviteURL}>
                  <IconButton>
                    <ContentCopyIcon />
                  </IconButton>
                </CopyToClipboard>
              </Stack>
            </DialogContentText>
          ) : (
            <>
              <DialogContentText>
                Invite a new user to Airplx. Enter their email address below and
                they'll recieve an email with instructions on how to join your
                team.
              </DialogContentText>
              <br />
              <Stack direction="row" spacing={2}>
                <TextField
                  inputProps={{
                    [`data-testid`]: "invite_email_address",
                  }}
                  autoFocus
                  label="Email Address"
                  type="email"
                  fullWidth
                  variant="outlined"
                  onChange={(evt) => setEmail(evt.target.value)}
                  value={email}
                />
                <TextField
                  select
                  variant="outlined"
                  label="Role"
                  value={role}
                  onChange={(evt) => setRole(evt.target.value)}
                  sx={{ width: 200 }}
                >
                  <MenuItem value="operator">operator</MenuItem>
                  <MenuItem value="manager">manager</MenuItem>
                </TextField>
              </Stack>
            </>
          )}
        </DialogContent>
        <DialogActions>
          {Boolean(inviteURL) ? (
            <Button color="success" variant="contained" onClick={handleClose}>
              OK
            </Button>
          ) : (
            <>
              <Button color="error" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                startIcon={<MailOutlineIcon />}
                variant="contained"
                color="success"
                onClick={async () => {
                  if (
                    [
                      `@gmail.com`,
                      `@icloud.com`,
                      `@yahoo.com`,
                      `@hotmail.com`,
                    ].some((domain) => email.includes(domain))
                  ) {
                    setConfirm({
                      title: "Personal E-mail Warning",
                      text: `You’re adding a team member using an email address outside of your organization. Using a personal email (Gmail, iCloud, etc) is allowed, but remember to remove the user’s AirPlx access in “Settings” if needed later on.`,
                      onOk: async () => {
                        const inviteURL = await makeInvite({
                          fbo_id,
                          email,
                          default_role: role,
                        });
                        setInviteURL(inviteURL);
                        setEmail("");
                      },
                      onCancel() {
                        setConfirm(null);
                      },
                      onClose: () => {
                        setConfirm(null);
                      },
                    });
                    return;
                  }
                  const inviteURL = await makeInvite({
                    fbo_id,
                    email,
                    default_role: role,
                  });
                  setInviteURL(inviteURL);
                  setEmail("");
                }}
              >
                Invite
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      {confirm && <ConfirmationDialog {...confirm} />}
    </div>
  );
};
function useFBOState(): { activeFBO: any } {
  throw new Error("Function not implemented.");
}
