import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { format as formatDate } from "date-fns";
import * as React from "react";
import { HangarStateContainer } from "../../containers/HangarStateContainer";
import { useThisHangar } from "../../hooks/useThisHangar";
import { Stack as StackType } from "../../types";
import { StackResult } from "../Hangar/EvaluatingStackDialog/StackResult";
import { FlaggedStack } from "./FlaggedStacks";

const StackResultsGridDialog: React.FC<{
  stacks: StackType[];
  onClose: () => void;
}> = ({ stacks, onClose }) => {
  const { hangar } = useThisHangar();
  return (
    <Dialog open={true} maxWidth="xl" onClose={onClose}>
      <DialogContent>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          {stacks.map((stack, idx) => (
            <Grid
              key={`flagged-stack-result-${stack.id}`}
              item
              xs={12}
              md={6}
              justifyContent="center"
              alignItems="center"
              alignContent="center"
            >
              <StackResult
                readOnly
                flagged={idx === 0}
                status={"complete"}
                hangar={hangar}
                stack={stack}
                setHangar={() => null}
                onClose={onClose}
                onFlagResult={async (id, isFlagged) => {
                  // noop
                }}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const FlaggedStacksGrid: React.FC<{ flaggedStacks: FlaggedStack[] }> = ({
  flaggedStacks,
}) => {
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 300,
    },
    {
      field: "hangar_name",
      headerName: "Hangar",
      width: 200,
      valueGetter: (value, row) => row.hangar.name,
    },
    {
      field: "created_at",
      headerName: "Date",
      width: 225,
      renderCell: (params: GridRenderCellParams) =>
        formatDate(new Date(params.row.created_at), "yyyy-MM-dd hh:mm:ss a z"),
    },
    {
      field: "action",
      headerName: "",
      sortable: false,
      width: 200,
      renderCell: (params: GridRenderCellParams) => {
        const [open, setOpen] = React.useState<boolean>(false);
        return (
          <>
            <Button
              size="small"
              variant="contained"
              color="success"
              onClick={() => setOpen(true)}
            >
              View
            </Button>

            <HangarStateContainer
              initialState={{
                hangar_id: params.row.hangar_id,
                isReference: false,
              }}
            >
              {open && (
                <StackResultsGridDialog
                  stacks={[params.row.stack].concat(
                    params.row.alternate_stacks
                  )}
                  onClose={() => setOpen(false)}
                />
              )}
            </HangarStateContainer>
          </>
        );
      },
    },
  ];
  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <DataGrid
        getRowId={(row) => row.id}
        rows={flaggedStacks}
        disableRowSelectionOnClick
        columns={columns}
      />
    </Box>
  );
};

type Props = {
  flaggedStacks: FlaggedStack[];
};

export const FlaggedStacksPresenter: React.FC<Props> = ({
  flaggedStacks,
  ...props
}) => {
  return (
    <Box sx={{ ml: 2, mr: 2, height: "100%" }}>
      <Stack direction="column" sx={{ height: "100%" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ mt: 2, mb: 2 }}
        >
          <Typography variant="h6">Flagged Stacks</Typography>
        </Stack>
        <Divider />
        <FlaggedStacksGrid flaggedStacks={flaggedStacks} />
      </Stack>
    </Box>
  );
};
