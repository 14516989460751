import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { orderBy } from "lodash";
import * as React from "react";
import { Link } from "react-router-dom";
import { useActiveFBOs } from "../../containers/ActiveFBOContainer";
import { useApi } from "../../containers/ApiContainer";
import { AddFBO } from "../../widgets/AddFBO";
import { ConvertSVG } from "../ConvertSVG";
import { EditFboName } from "./EditFboName";

type Props = {};

export const SuperAdmin: React.FC<Props> = () => {
  const { fbos, setFBO } = useActiveFBOs();
  const [activeTab, setActiveTab] = React.useState<number>(0);
  const [testEmail, setTestEmail] = React.useState<string>("");
  const [testResult, setTestResult] = React.useState<boolean>();
  const [emailOrDomain, setEmailOrDomain] = React.useState<string>("");
  const [authorizedAccounts, setAuthorizedAccounts] = React.useState<
    { email_or_domain: string }[]
  >([]);
  const { postgrest } = useApi();

  const fetchAuthorizedAccounts = async () => {
    const result = await postgrest.from("authorized_account").select("*");
    return result.data;
  };

  React.useEffect(() => {
    fetchAuthorizedAccounts().then(setAuthorizedAccounts);
  }, []);

  const sortedFBOs = React.useMemo(() => {
    return orderBy(fbos, ["name"], ["asc"]);
  }, [fbos]);

  return (
    <Box sx={{ p: 2 }}>
      <Tabs value={activeTab} onChange={(evt, value) => setActiveTab(value)}>
        <Tab value={0} label="Authorized Accounts" />
        <Tab value={1} label="FBOs" />
        <Tab value={2} label="Labeling" />
        <Tab value={3} label="Convert SVGs" />
      </Tabs>
      {activeTab === 0 && (
        <Stack direction="column" spacing={1} sx={{ width: 400 }}>
          <List
            subheader={
              <>
                <ListSubheader component="div" id="nested-list-subheader">
                  Valid Emails/Domains
                </ListSubheader>
                <Divider />
              </>
            }
          >
            {authorizedAccounts.map((authorizedAccount) => (
              <ListItem key={authorizedAccount.email_or_domain}>
                <ListItemText primary={authorizedAccount.email_or_domain} />
              </ListItem>
            ))}
          </List>
          <Stack direction="row" spacing={1}>
            <TextField
              fullWidth
              placeholder="New Email/Domain"
              size="small"
              onChange={(e) => setEmailOrDomain(e.target.value)}
              value={emailOrDomain}
            />
            <Button
              variant="contained"
              size="small"
              onClick={async () => {
                await postgrest
                  .from("authorized_account")
                  .insert({ email_or_domain: emailOrDomain });
                fetchAuthorizedAccounts().then(setAuthorizedAccounts);
                setEmailOrDomain("");
              }}
            >
              Add
            </Button>
          </Stack>
          <Stack direction="column" spacing={1}>
            <Stack direction="row" spacing={1}>
              <TextField
                fullWidth
                size="small"
                placeholder="Email to test"
                onChange={(e) => {
                  setTestResult(null);
                  setTestEmail(e.target.value);
                }}
                value={testEmail}
              />
              <Button
                variant="contained"
                size="small"
                onClick={async () => {
                  setTestResult(null);
                  const result = await postgrest.rpc("is_authorized", {
                    email: testEmail,
                  });
                  setTestResult(result.data as boolean);
                }}
              >
                Test
              </Button>
            </Stack>
            <Stack direction="row" spacing={1}>
              {testResult === true && <CheckIcon sx={{ color: "green" }} />}
              {testResult === false && <CloseIcon sx={{ color: "red" }} />}
              <Typography>
                {testResult === true &&
                  "Passed. This email will be allowed access."}
                {testResult === false &&
                  "Failed. This email will not be allowed access."}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      )}
      {activeTab === 1 && (
        <Stack direction="column" spacing={2} sx={{ width: 400, p: 1 }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>Add a new FBO</Typography>
            <AddFBO />
          </Stack>
          <Divider />
          <Stack direction="column">
            <Typography>Current FBOs</Typography>
            <Typography variant="caption" color="textSecondary">
              Modify the text input to change the FBO Name. Change their
              subscription (gated feature access) by selecting from the
              dropdown.
            </Typography>
          </Stack>
          {sortedFBOs.map((fbo) => (
            <Stack
              key={`fbo-${fbo.id}`}
              direction="row"
              spacing={1}
              alignItems="center"
            >
              <EditFboName
                name={fbo?.name}
                onChange={(name) => {
                  setFBO({
                    ...fbo,
                    name: name,
                  });
                }}
              />
              <TextField
                size="small"
                select
                fullWidth
                label="Subscription"
                value={fbo.subscription}
                onChange={(evt) => {
                  setFBO({
                    ...fbo,
                    subscription: evt.target.value as any,
                  });
                }}
              >
                <MenuItem value={"standard"}>Standard</MenuItem>
                <MenuItem value={"autostacking"}>Autostacking</MenuItem>
                <MenuItem value={"autostacking_with_edit"}>
                  Autostacking with Hangar/Ramp Edit
                </MenuItem>
              </TextField>
            </Stack>
          ))}
        </Stack>
      )}
      {activeTab === 2 && (
        <Stack
          direction="column"
          spacing={2}
          p={4}
          justifyContent="center"
          alignItems="center"
          width={400}
        >
          <Typography variant="h5">Enter the dojo 🥷</Typography>
          <Button
            component={Link}
            to="/labeling-tool"
            size="large"
            color="success"
            variant="contained"
          >
            Let's Start Labeling 🚀
          </Button>
        </Stack>
      )}
      {activeTab === 3 && <ConvertSVG />}
    </Box>
  );
};
