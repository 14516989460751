import { useCallback } from "react";
import { Ramp } from "../../../../../../types";
import { uuidv4 } from "../../../../../../utils";
import {
  isOnText,
  isPointInShapeOrOnEdge,
  pointToSegmentDistance,
} from "../math";
import { DraggingPoint, DraggingShape, Shape } from "../RampCanvas";

// Start dragging a point (either from existing shapes or the current shape)
type Props = {
  selectedTool: string;
  setSelectedTool: (tool: string) => void;
  shapes: Shape[];
  setShapes: React.Dispatch<React.SetStateAction<Shape[]>>;
  currentShape: Shape;
  setDraggingPoint: React.Dispatch<React.SetStateAction<DraggingPoint>>;
  setDraggingShape: React.Dispatch<React.SetStateAction<DraggingShape>>;
  draggingPoint: DraggingPoint;
  draggingShape: DraggingShape;
  canvasRef: React.RefObject<HTMLCanvasElement>;
  ramp: Ramp;
  setHistory: React.Dispatch<React.SetStateAction<Shape[][]>>;
};

export const useOnMouseDown = (props: Props) => {
  const {
    selectedTool,
    setSelectedTool,
    shapes,
    setShapes,
    currentShape,
    setDraggingPoint,
    setDraggingShape,
    draggingPoint,
    draggingShape,
    canvasRef,
    ramp,
    setHistory,
  } = props;

  return useCallback(
    (event: React.MouseEvent) => {
      if (selectedTool === "Measuring") {
        return;
      }

      setHistory((previousHistory) => {
        // check if the md5 of the last shape is the same as the current shape
        // if it is, don't add it to the history
        if (
          previousHistory.length > 0 &&
          JSON.stringify(previousHistory[previousHistory.length - 1]) ===
            JSON.stringify(shapes)
        ) {
          return previousHistory;
        }
        return [...previousHistory, shapes];
      });

      const rect = canvasRef.current?.getBoundingClientRect();
      if (!rect) {
        return;
      }

      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;

      // if we're in Text mode, add a text pill
      if (selectedTool === "Text") {
        const text = prompt("Enter text");
        if (text) {
          const updatedShapes = [...shapes];
          updatedShapes.unshift({
            id: uuidv4(),
            fbo_id: ramp.fbo_id,
            ramp_id: ramp.id,
            tags: [],
            points: [{ x, y }],
            locked: false,
            type: "Text",
            stroke_style: "white",
            line_style: "solid",
            line_width: 3,
            text,
            font_size: 18,
          });
          setShapes(updatedShapes);
          setSelectedTool("Select");
          return;
        }
      }

      // Check if we're clicking on a point in an existing shape
      for (const shape of shapes) {
        if (shape.locked) {
          continue;
        }

        const pointIndex = shape.points.findIndex(
          (point) => Math.hypot(point.x - x, point.y - y) < 10
        );

        if (pointIndex !== -1) {
          setDraggingPoint({ shapeId: shape.id, pointIndex });
          console.log("dragging point");
          return;
        }

        if (shape.type === "Text") {
          // since Text is just a single point, just evaluate that point w/ a nice buffer
          // adjust for the length of the text. the user will perceive the shape as being
          // the full length of the text on the screen
          const canvas = canvasRef.current;
          if (isOnText(canvas, shape, { x, y })) {
            setDraggingShape({ shapeId: shape.id, offset: { x, y } });
            console.log("dragging text");
            return;
          }
        }
        // check if the mouse is inside the shape
        if (isPointInShapeOrOnEdge({ x, y }, shape.points)) {
          setDraggingShape({ shapeId: shape.id, offset: { x, y } });
          return;
        }
        // Check if the mouse is close to any line segment of the shape
        for (let j = 0; j < shape.points.length; j++) {
          const start = shape.points[j];
          const end = shape.points[(j + 1) % shape.points.length];
          const distToLine = pointToSegmentDistance({ x, y }, start, end);

          if (distToLine < 10) {
            // Threshold to detect the line
            setDraggingShape({ shapeId: shape.id, offset: { x, y } });
            console.log("dragging shape");
            return;
          }
        }
      }

      // Check if we're clicking on a point in the current shape
      const pointIndex = currentShape.points.findIndex(
        (point) => Math.hypot(point.x - x, point.y - y) < 10
      );

      if (pointIndex !== -1) {
        setDraggingPoint({ shapeId: null, pointIndex });
      }
    },
    [props]
  );
};
