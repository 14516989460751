import { useCallback } from "react";
import { isPointInShapeOrOnEdge } from "../math";
import { ContextMenu, Shape } from "../RampCanvas";

type Props = {
  selectedTool: string;
  shapes: Shape[];
  setContextMenu: React.Dispatch<React.SetStateAction<ContextMenu>>;
  canvasRef: React.RefObject<HTMLCanvasElement>;
};

export const useOnContextMenu = (props: Props) => {
  const { selectedTool, shapes, setContextMenu, canvasRef } = props;
  return useCallback(
    (event: React.MouseEvent) => {
      if (selectedTool === "Measuring") {
        return;
      }
      event.preventDefault();
      const rect = canvasRef.current?.getBoundingClientRect();
      if (!rect) return;

      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;

      // Check if the right-click is within any shape
      const clickedShape = shapes
        .filter((s) => !s.locked)
        .find((shape) => {
          // since Text is just a single point, just evaluate that point w/ a nice buffer
          if (shape.type === "Text") {
            // adjust for the length of the text. the user will perceive the shape as being
            // the full length of the text on the screen
            const textWidth = shape.text.length * shape.font_size;
            const textHeight = shape.font_size;
            const points = [
              {
                x: shape.points[0].x - textWidth / 2,
                y: shape.points[0].y - textHeight / 2,
              },
              {
                x: shape.points[0].x + textWidth / 2,
                y: shape.points[0].y - textHeight / 2,
              },
              {
                x: shape.points[0].x + textWidth / 2,
                y: shape.points[0].y + textHeight / 2,
              },
              {
                x: shape.points[0].x - textWidth / 2,
                y: shape.points[0].y + textHeight / 2,
              },
            ];
            return isPointInShapeOrOnEdge({ x, y }, points);
          }

          return isPointInShapeOrOnEdge({ x, y }, shape.points);
        });

      if (clickedShape) {
        console.log(
          `Context menu triggered within a shape [${clickedShape.id}] at:`,
          {
            x,
            y,
          }
        );
        console.log(clickedShape);
        setContextMenu({ x, y, shapeId: clickedShape.id });
      } else {
        console.log("Right-click outside any shape at:", { x, y });
      }
    },
    [props]
  );
};
