import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InfoIcon from "@mui/icons-material/Info";
import { Divider, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { difference, featureCollection, polygon, union } from "@turf/turf";
import { Feature, Polygon } from "geojson";
import { round, sum } from "lodash";
import * as numeral from "numeral";
import * as React from "react";
import { Ramp } from "../../types";
import { Shape } from "../Settings/RampsSettings/RampEditor/RampCanvas/RampCanvas";

const calculatePolygonArea = (coordinates: number[][]) => {
  let n = coordinates.length;
  let area = 0;

  for (let i = 0; i < n - 1; i++) {
    const [x1, y1] = coordinates[i];
    const [x2, y2] = coordinates[i + 1];

    // Shoelace formula summation
    area += x1 * y2 - y1 * x2;
  }

  // Finalize the calculation (absolute value)
  return Math.abs(area) / 2;
};

export const combinePolygons = (polygons: Feature<Polygon>[]) => {
  if (!polygons.length) {
    return null;
  }
  if (polygons.length === 1) {
    return polygons[0];
  }
  return union(featureCollection(polygons));
};

export const shapeToPolygon = (shape: Shape) => {
  return polygon([
    [
      ...shape.points.map((point) => [point.x, point.y]),
      [shape.points[0].x, shape.points[0].y],
    ],
  ]);
};

export const calculateUsableArea = (ramp: Ramp) => {
  try {
    const parkablePolygons = ramp.shapes
      .filter((s) => s.tags.includes("parkable"))
      .map((shape) => shapeToPolygon(shape));

    if (!parkablePolygons.length) {
      return 0;
    }

    const obstructionPolygons = ramp.shapes
      .filter((s) => s.tags.includes("obstruction"))
      .map(shapeToPolygon);

    const combinedGeometry = combinePolygons(parkablePolygons);

    if (obstructionPolygons.length === 0) {
      return calculatePolygonArea(
        combinedGeometry.geometry.coordinates[0] as number[][]
      );
    }

    const obstructionGeometry = combinePolygons(obstructionPolygons);
    // remove obstruction from parkable area
    const parkableGeometry = difference(
      featureCollection([combinedGeometry, obstructionGeometry])
    );

    const parkableArea = calculatePolygonArea(
      parkableGeometry.geometry.coordinates[0] as number[][]
    );
    const obstructionArea = sum(
      parkableGeometry.geometry.coordinates
        .slice(1)
        .map((c) => calculatePolygonArea(c as number[][]))
    );

    return parkableArea - obstructionArea;
  } catch (e) {
    console.error(e);
    return 0;
  }
};

type Props = {
  ramp: Ramp;
  onClickBack?: () => void;
};

export const RampInfoCard: React.FC<Props> = ({ ramp, onClickBack }) => {
  const sqft =
    ramp?.version === "v3"
      ? numeral(calculateUsableArea(ramp)).format("0,")
      : "n/a";

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        p: 1,
        maxHeight: "100%",
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center">
          {Boolean(onClickBack) && (
            <IconButton onClick={onClickBack}>
              <ArrowBackIcon />
            </IconButton>
          )}
          <Typography>
            {ramp.stack.isReference && `[All Tie Down Tenants] `}
            {ramp.name}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="column" sx={{ width: "100%" }}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Typography textAlign="center">Aircraft</Typography>
            <Tooltip title="# aircraft on the ramp">
              <InfoIcon sx={{ color: "info.dark" }} fontSize="small" />
            </Tooltip>
          </Stack>
          <Typography variant="caption" textAlign="center" color="info.dark">
            {`${ramp.stack?.tenants?.length ?? 0}`}
          </Typography>
        </Stack>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Stack direction="column" sx={{ width: "100%" }}>
          <Typography textAlign="center">Gross Dimensions</Typography>
          <Typography variant="caption" textAlign="center" color="info.dark">
            {round(ramp.depth, 0)}L x {round(ramp.width)}W
          </Typography>
        </Stack>
      </Stack>
      {ramp?.version === "v3" && (
        <>
          <Divider />
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="column" sx={{ width: "100%" }}>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Typography textAlign="center">Parkable Area</Typography>
                <Tooltip title="Total square footage of parkable space on the ramp">
                  <InfoIcon sx={{ color: "info.dark" }} fontSize="small" />
                </Tooltip>
              </Stack>
              <Typography
                variant="caption"
                textAlign="center"
                color="info.dark"
              >
                {sqft} ft²
              </Typography>
            </Stack>
            <Stack direction="column" sx={{ width: "100%" }}></Stack>
          </Stack>
        </>
      )}
    </Stack>
  );
};
