import { useCallback } from "react";
import { Ramp } from "../../../../../../types";
import {
  adjustSquare,
  getCenter,
  isOnText,
  isPointInShapeOrOnEdge,
  pointToSegmentDistance,
  snapSquare,
} from "../math";
import { DraggingPoint, DraggingShape, Shape } from "../RampCanvas";
import { updateAtId } from "../utils";

type Props = {
  selectedTool: string;
  setSelectedTool: (tool: string) => void;
  shapes: Shape[];
  setShapes: React.Dispatch<React.SetStateAction<Shape[]>>;
  currentShape: Shape;
  setDraggingPoint: React.Dispatch<React.SetStateAction<DraggingPoint>>;
  setDraggingShape: React.Dispatch<React.SetStateAction<DraggingShape>>;
  draggingPoint: DraggingPoint;
  draggingShape: DraggingShape;
  canvasRef: React.RefObject<HTMLCanvasElement>;
  ramp: Ramp;
  isShiftPressed: boolean;
  setMousePosition: React.Dispatch<
    React.SetStateAction<{ x: number; y: number }>
  >;
  getActiveShapes: () => Shape[];
};

export const useOnMouseMove = (props: Props) => {
  const {
    selectedTool,
    shapes,
    setShapes,
    currentShape,
    setDraggingPoint,
    setDraggingShape,
    draggingPoint,
    draggingShape,
    canvasRef,
    ramp,
    isShiftPressed,
    setMousePosition,
    getActiveShapes,
  } = props;
  return useCallback(
    (event: React.MouseEvent) => {
      if (selectedTool === "Measuring") {
        return;
      }

      const rect = canvasRef.current?.getBoundingClientRect();
      if (!rect) {
        return;
      }

      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      setMousePosition({ x, y });

      if (draggingPoint) {
        const { shapeId, pointIndex } = draggingPoint;
        if (shapeId) {
          const updatedShape = shapes.find((s) => s.id === shapeId);
          if (
            updatedShape.type === "Tie Down T" ||
            updatedShape.type === "Double T" ||
            updatedShape.type === "Circle" ||
            updatedShape.type === "Helipad"
          ) {
            // Fixed adjustment logic for square
            const center = getCenter(updatedShape.points);
            const adjustedPoints = adjustSquare(
              updatedShape.points,
              pointIndex,
              { x, y },
              center
            );
            // if shift is pressed, snap to 45 degree angles
            if (isShiftPressed) {
              //  TODO: implement here
              updatedShape.points = snapSquare(adjustedPoints);
            } else {
              updatedShape.points = adjustedPoints;
            }
          } else {
            updatedShape.points[pointIndex] = { x, y };
          }
          setShapes(updateAtId(shapes, updatedShape));
        } else if (draggingShape) {
          // Update point in an existing shape
          let updatedShapes = [...shapes];
          const draggingShape = shapes.find((s) => s.id === shapeId);
          if (
            draggingShape?.type === "Tie Down T" ||
            draggingShape?.type === "Double T" ||
            draggingShape?.type === "Circle" ||
            draggingShape?.type === "Helipad"
          ) {
            // Fixed adjustment logic for square
            const center = getCenter(draggingShape.points);
            const adjustedPoints = adjustSquare(
              draggingShape.points,
              pointIndex,
              { x, y },
              center
            );
            // if shift is pressed, snap to 45 degree angles
            if (isShiftPressed) {
              //  TODO: implement here
              draggingShape.points = snapSquare(adjustedPoints);
            } else {
              draggingShape.points = adjustedPoints;
            }
          } else {
            draggingShape.points[pointIndex] = { x, y };
          }
          setShapes(updateAtId(updatedShapes, draggingShape));
        }
      } else if (draggingShape) {
        const { shapeId, offset } = draggingShape;
        const dx = x - offset.x;
        const dy = y - offset.y;

        let updatedShapes = [...shapes];
        getActiveShapes().forEach((shape) => {
          updatedShapes = updateAtId(updatedShapes, {
            ...shape,
            points: shape.points.map((point) => ({
              x: point.x + dx,
              y: point.y + dy,
            })),
          });
        });
        setShapes(updatedShapes);
        setDraggingShape({ shapeId, offset: { x, y } });
      } else {
        return;
        // Check if hovering over a completed shape. this should look for
        // if it's inside a shape or on one of its edges
        const hoveredShape = shapes.find((shape) => {
          if (shape.locked) {
            return false;
          }
          return (
            isOnText(canvasRef.current, shape, { x, y }) ||
            shape.points.some(
              (point) => Math.hypot(point.x - x, point.y - y) < 10
            ) ||
            isPointInShapeOrOnEdge({ x, y }, shape.points) ||
            shape.points.some((point, pointIndex) => {
              const nextPoint =
                shape.points[(pointIndex + 1) % shape.points.length];
              return pointToSegmentDistance({ x, y }, point, nextPoint) < 10;
            })
          );
        });
      }
    },
    [props]
  );
};
