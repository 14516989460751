import {
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { PercentageInput } from "../../../widgets/PercentInput/PercentInput";
import React = require("react");

type Props = {
  baseFee: number;
};

export const AnnualTenantTable: React.FC<Props> = ({ baseFee }) => {
  const [percentageIncrease, setPercentageIncrease] = useState<number>(5);

  // Calculate fees for 5 years
  const fees = Array.from({ length: 5 }, (_, i) => {
    if (!baseFee) {
      return "---";
    }
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(baseFee * Math.pow(1 + percentageIncrease / 100, i));
  });

  return (
    <Stack direction="column" spacing={1} maxWidth={400}>
      <Typography variant="h6" align="center">
        Annual Tenant Fees
      </Typography>
      <Divider />
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="center"
      >
        <Typography>Annual % Increase</Typography>
        <PercentageInput
          initialValue={percentageIncrease}
          onChange={(value) => setPercentageIncrease(value)}
        />
      </Stack>
      <Table>
        <TableBody>
          {fees.map((fee, index) => (
            <TableRow key={index}>
              <TableCell align="center">{`Year ${index + 1}`}</TableCell>
              <TableCell align="center">{fee}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Stack>
  );
};
