import { useRef } from "react";
import { useAsync } from "react-use";

import { createImagePattern } from "../drawing";

export const FILL_PATTERNS = () => {
  const BASE_PATTERNS = [
    {
      label: "Red Stripe",
      value: "/diagonal_red_transparent_stripes.png",
    },
    {
      label: "Yellow Stripe",
      value: "/diagonal_yellow_transparent_stripes.png",
    },
    {
      label: "White Stripe",
      value: "/diagonal_white_transparent_stripes.png",
    },
  ];
  const patterns = [];
  for (const pattern of BASE_PATTERNS) {
    for (let i = 0.3; i <= 1; i += 0.3) {
      patterns.push({
        label: `${pattern.label} ${(i * 100).toFixed(0)}%`,
        value: pattern.value,
        opacity: i,
      });
    }
  }

  return patterns;
};

export const usePatterns = () => {
  const tempCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const { loading, value: patterns } = useAsync(async () => {
    // Create a temporary canvas element if it doesn't exist
    if (!tempCanvasRef.current) {
      tempCanvasRef.current = document.createElement("canvas");
      tempCanvasRef.current.width = 256; // Adjust size as needed
      tempCanvasRef.current.height = 256;
    }
    // Map and create patterns
    const promises = FILL_PATTERNS().map(async (pattern) => {
      return [
        // `${pattern.value}?opacity=${pattern.opacity}`,
        pattern.label,
        await createImagePattern(
          tempCanvasRef.current!,
          pattern.value,
          pattern.opacity
        ),
      ];
    });

    return Object.fromEntries(await Promise.all(promises));
  }, []);
  return { loading, patterns };
};
