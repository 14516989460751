import HelpIcon from "@mui/icons-material/Help";
import LogoutIcon from "@mui/icons-material/Logout";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import SettingsIcon from "@mui/icons-material/Settings";
import SupportIcon from "@mui/icons-material/Support";
import {
  Box,
  Divider,
  IconButton,
  List,
  Drawer as MuiDrawer,
  Stack,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import * as React from "react";
import logo from "../../../airplx-logo-transparent-square-no-text.png";
import { useFeatureFlags } from "../../containers/FeatureFlagsContainer";
import { useIdentity } from "../../containers/IdentityContainer";
import { AirplaneTailsIcon } from "../Icons/AirplaneTailsIcon";
import { HangarIcon } from "../Icons/HangarIcon";
import { RampIcon } from "../Icons/RampIcon";
import { FBOMenu } from "./FBOMenu";

export const DRAWER_WIDTH = 73;
export const SIDE_DRAWER_WIDTH = 240;

const openedMixin = (theme) => ({
  width: SIDE_DRAWER_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflow: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflow: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

export const DrawerHeader = styled("div", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: open ? "flex-end" : "flex-center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    // ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      backgroundColor: "white",
      color: "white",
    },
  }),
  ...(!open && {
    // ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
      backgroundColor: "white",
      color: "white",
    },
  }),
}));

export const SideIconButton = ({
  active = false,
  onClickRoute,
  label,
  icon,
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  return (
    <Stack sx={{ m: 1.5 }}>
      <Tooltip title={label} placement="right">
        <IconButton
          size="large"
          sx={{
            color: active ? "white" : "primary.main",
            backgroundColor: active ? "primary.main" : "inherit",
            "&:hover": {
              color: active ? "primary.main" : "primary.main",
              backgroundColor: active ? "grey" : "grey",
            },
          }}
          onClick={onClickRoute}
        >
          {icon}
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

interface SideDrawerProps {
  open: boolean;
  setOpen: (x: boolean) => void;
  onClickRoute: (pathname: string) => void;
  showAdmin: boolean;
}

export const SideDrawer: React.FC<SideDrawerProps> = ({
  open,
  setOpen,
  showAdmin = true,
  onClickRoute,
}) => {
  const { airplxIdentity, logout } = useIdentity();
  const [active, setActive] = React.useState<string>(window.location.hash);
  const isDev = useFeatureFlags().isDev;

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        zIndex: 4000 + 1249,
        width: DRAWER_WIDTH,
        height: "100%",
        overflow: "hidden",
      }}
    >
      <img
        data-testid="sidedrawer_logo"
        src={logo}
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          width: 25,
          marginTop: 10,
        }}
      />
      <Stack
        direction="column"
        justifyContent="space-between"
        sx={{ height: "100%" }}
      >
        <List sx={{ mt: 0, pt: 0 }}>
          <SideIconButton
            active={active.indexOf("#/hangars") > -1}
            label="Hangars"
            icon={<HangarIcon />}
            onClickRoute={() => {
              window.location.hash = `/hangars`;
              setActive(window.location.hash);
            }}
          />
          <SideIconButton
            active={active.indexOf("#/ramps") > -1}
            label="Ramps"
            icon={<RampIcon />}
            onClickRoute={() => {
              window.location.hash = `/ramps`;
              setActive(window.location.hash);
            }}
          />
          <SideIconButton
            active={active === "#/tenants"}
            label="Transient & Tenant Aircraft"
            icon={<AirplaneTailsIcon />}
            onClickRoute={() => {
              window.location.hash = `/tenants`;
              setActive(window.location.hash);
            }}
          />
          <SideIconButton
            active={active === "#/fee-calculator"}
            label="Fee Calculator"
            icon={<MonetizationOnIcon />}
            onClickRoute={() => {
              window.location.hash = `/fee-calculator`;
              setActive(window.location.hash);
            }}
          />
          <SideIconButton
            active={false}
            label="Help"
            icon={<HelpIcon />}
            onClickRoute={() => {
              window
                .open(
                  "https://help.airplx.com/docs/Hangars%20and%20Ramps/Current%20Hangar",
                  "_blank"
                )
                .focus();
            }}
          />
        </List>
        <Box>
          {airplxIdentity.isadmin && (
            <>
              {/* <SideIconButton
                active={active.startsWith("#/flagged-stacks")}
                label="Flagged Stacks"
                icon={<FilledFlagIcon />}
                onClickRoute={() => {
                  window.location.hash = `/flagged-stacks`;
                  setActive(window.location.hash);
                }}
              /> */}
              {/* <SideIconButton
                active={active.startsWith("#/stackmaster")}
                label="StackMaster"
                icon={<SpeedIcon />}
                onClickRoute={() => {
                  window.location.hash = `/stackmaster`;
                  setActive(window.location.hash);
                }}
              /> */}
              {/* <SideIconButton
                active={active === "#/labeling-tool"}
                label="Labeling Tool"
                icon={<LabelIcon />}
                onClickRoute={() => {
                  window.location.hash = `/labeling-tool`;
                  setActive(window.location.hash);
                }}
              /> */}
              {/* <SideIconButton
                active={active === "#/convert-svg"}
                label="Convert SVG"
                icon={<CenterFocusWeakIcon />}
                onClickRoute={() => {
                  window.location.hash = `/convert-svg`;
                  setActive(window.location.hash);
                }}
              /> */}
              <Divider />
            </>
          )}
          <SideIconButton
            active={active === "#/super-admin"}
            label="Super Admin"
            icon={<SupportIcon />}
            onClickRoute={() => {
              window.location.hash = `/super-admin`;
              setActive(window.location.hash);
            }}
          />
          <SideIconButton
            active={active.indexOf("#/settings") > -1}
            label="Settings"
            icon={<SettingsIcon />}
            onClickRoute={() => {
              window.location.hash = `/settings`;
              setActive(window.location.hash);
            }}
          />
          <FBOMenu />
          <SideIconButton
            label="Logout"
            icon={<LogoutIcon />}
            onClickRoute={() => {
              logout();
              window.location.hash = `/#/login`;
            }}
          />
        </Box>
      </Stack>
    </Drawer>
  );
};
