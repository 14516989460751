import { Point, Shape } from "./RampCanvas";
import { measureText } from "./drawing";

export const isOnText = (
  canvas: HTMLCanvasElement,
  shape: Shape,
  point: Point
) => {
  const padding = 10;
  const context = canvas?.getContext("2d");
  const textWidth = measureText(context, shape).width;
  const textHeight = shape.font_size;
  const points = [
    {
      x: shape.points[0].x - textWidth / 2 - padding,
      y: shape.points[0].y - textHeight / 2 - padding,
    },
    {
      x: shape.points[0].x + textWidth / 2 + padding,
      y: shape.points[0].y - textHeight / 2 - padding,
    },
    {
      x: shape.points[0].x + textWidth / 2 + padding,
      y: shape.points[0].y + textHeight / 2 + padding,
    },
    {
      x: shape.points[0].x - textWidth / 2 - padding,
      y: shape.points[0].y + textHeight / 2 + padding,
    },
  ];
  return isPointInShapeOrOnEdge(point, points);
};

// Helper function to check if a point is within a shape or on its edges
export const isPointInShapeOrOnEdge = (
  point: Point,
  shapePoints: Point[]
): boolean => {
  // Handle the case where the shape is a single point
  if (shapePoints.length === 1) {
    return (
      Math.hypot(point.x - shapePoints[0].x, point.y - shapePoints[0].y) < 10
    );
  }

  // Check if the point is inside the shape using ray-casting algorithm
  let isInside = false;
  for (let i = 0, j = shapePoints.length - 1; i < shapePoints.length; j = i++) {
    const xi = shapePoints[i].x,
      yi = shapePoints[i].y;
    const xj = shapePoints[j].x,
      yj = shapePoints[j].y;

    const intersect =
      yi > point.y !== yj > point.y &&
      point.x < ((xj - xi) * (point.y - yi)) / (yj - yi) + xi;
    if (intersect) {
      isInside = !isInside;
    }
  }

  // Check if the point is on any of the edges of the shape
  for (let i = 0, j = shapePoints.length - 1; i < shapePoints.length; j = i++) {
    if (pointToSegmentDistance(point, shapePoints[i], shapePoints[j]) < 10) {
      return true;
    }
  }

  return isInside;
};

export const pointToSegmentDistance = (
  point: Point,
  start: Point,
  end: Point
): number => {
  const dx = end.x - start.x;
  const dy = end.y - start.y;
  const lengthSquared = dx * dx + dy * dy;
  let t = ((point.x - start.x) * dx + (point.y - start.y) * dy) / lengthSquared;
  t = Math.max(0, Math.min(1, t));
  const nearestX = start.x + t * dx;
  const nearestY = start.y + t * dy;
  return Math.hypot(point.x - nearestX, point.y - nearestY);
};

export const smoothLine = (
  points: Point[],
  smoothingFactor = 0.75
): Point[] => {
  // No smoothing needed if less than 3 points
  if (points.length < 3) {
    return points;
  }

  const smoothedPoints: Point[] = [];

  // Generate smoothed points using Catmull-Rom spline
  for (let i = 0; i < points.length - 1; i++) {
    const p0 = points[i - 1] || points[i]; // Previous point (or duplicate of current for edge cases)
    const p1 = points[i]; // Current point
    const p2 = points[i + 1]; // Next point
    const p3 = points[i + 2] || points[i + 1]; // Point after next (or duplicate for edge cases)

    // Generate control points
    for (let t = 0; t <= 1; t += smoothingFactor) {
      const t2 = t * t;
      const t3 = t2 * t;

      const x =
        0.5 *
        (2 * p1.x +
          (-p0.x + p2.x) * t +
          (2 * p0.x - 5 * p1.x + 4 * p2.x - p3.x) * t2 +
          (-p0.x + 3 * p1.x - 3 * p2.x + p3.x) * t3);

      const y =
        0.5 *
        (2 * p1.y +
          (-p0.y + p2.y) * t +
          (2 * p0.y - 5 * p1.y + 4 * p2.y - p3.y) * t2 +
          (-p0.y + 3 * p1.y - 3 * p2.y + p3.y) * t3);

      smoothedPoints.push({ x, y });
    }
  }

  return smoothedPoints;
};

export const snapTo45Degrees = (
  lastPoint: Point,
  currentPoint: Point
): Point => {
  // Calculate the difference in x and y
  const dx = currentPoint.x - lastPoint.x;
  const dy = currentPoint.y - lastPoint.y;

  // Calculate the angle in degrees
  const angle = Math.atan2(dy, dx) * (180 / Math.PI);

  // Snap angle to nearest 45 degrees
  const snappedAngle = Math.round(angle / 45) * 45;

  // Convert snapped angle back to radians
  const radians = (snappedAngle * Math.PI) / 180;

  // Calculate new dx and dy using the snapped angle and original distance
  const distance = Math.sqrt(dx * dx + dy * dy);
  const snappedDx = Math.cos(radians) * distance;
  const snappedDy = Math.sin(radians) * distance;

  // Return the new snapped point
  return {
    x: lastPoint.x + snappedDx,
    y: lastPoint.y + snappedDy,
  };
};

export const calculateArea = (points: Point[], scale: number = 1): number => {
  const numPoints = points.length;
  const scaledPoints = points.map((point) => ({
    x: point.x * scale,
    y: point.y * scale,
  }));

  // A shape with less than 3 points has no area
  if (numPoints < 3) {
    return 0;
  }

  let area = 0;

  for (let i = 0; i < numPoints; i++) {
    const j = (i + 1) % numPoints; // Wrap around to the first point
    area +=
      scaledPoints[i].x * scaledPoints[j].y -
      scaledPoints[j].x * scaledPoints[i].y;
  }

  return Math.abs(area / 2);
};

// Helper function to rotate a point around a center point by an angle in degrees
export const rotatePoint = (
  point: Point,
  center: Point,
  angle: number
): Point => {
  const radians = (angle * Math.PI) / 180;
  const cos = Math.cos(radians);
  const sin = Math.sin(radians);

  const translatedX = point.x - center.x;
  const translatedY = point.y - center.y;

  return {
    x: translatedX * cos - translatedY * sin + center.x,
    y: translatedX * sin + translatedY * cos + center.y,
  };
};

export const rotatePoints = (points: Point[], angle: number): Point[] => {
  const center = getCenter(points);
  // Rotate each point in the shape by the incremental angle change
  return points.map((point) => rotatePoint(point, center, angle));
};

// TODO: this overweights lopsided shapes
export const getCenter = (points: Point[]): Point => {
  let area = 0; // Signed area of the polygon
  let cx = 0; // X-coordinate of the centroid
  let cy = 0; // Y-coordinate of the centroid

  const n = points.length;

  // Loop through the polygon vertices
  for (let i = 0; i < n; i++) {
    const x0 = points[i].x;
    const y0 = points[i].y;
    const x1 = points[(i + 1) % n].x; // Wrap around for the last vertex
    const y1 = points[(i + 1) % n].y;

    const crossProduct = x0 * y1 - x1 * y0;

    area += crossProduct;
    cx += (x0 + x1) * crossProduct;
    cy += (y0 + y1) * crossProduct;
  }

  area *= 0.5;
  cx /= 6 * area;
  cy /= 6 * area;

  return { x: cx, y: cy };
};

export const adjustSquare = (
  points: Point[],
  draggedIndex: number,
  draggedPoint: Point,
  center: Point
): Point[] => {
  const oppositeIndex = (draggedIndex + 2) % 4; // Opposite vertex in a square
  const newPoints = [...points];

  // Calculate the vector from the center to the dragged point
  const dx = draggedPoint.x - center.x;
  const dy = draggedPoint.y - center.y;

  // Adjust the dragged point
  newPoints[draggedIndex] = draggedPoint;

  // Adjust the opposite point
  newPoints[oppositeIndex] = {
    x: center.x - dx,
    y: center.y - dy,
  };

  // Adjust the adjacent points to maintain proportions
  const adjacent1 = (draggedIndex + 1) % 4;
  const adjacent2 = (draggedIndex + 3) % 4;

  newPoints[adjacent1] = {
    x: center.x - dy,
    y: center.y + dx,
  };

  newPoints[adjacent2] = {
    x: center.x + dy,
    y: center.y - dx,
  };

  return newPoints;
};

export const insertNewPoint = (points: Point[], newPoint: Point): Point[] => {
  const newPoints: Point[] = [];
  // TODO: fix. put this between the correct points
  // Check if the point is on any of the edges of the shape
  for (let i = 0, j = points.length - 1; i < points.length; j = i++) {
    if (pointToSegmentDistance(newPoint, points[i], points[j]) < 10) {
      newPoints.push(newPoint);
    }
    newPoints.push(points[i]);
  }
  return newPoints;
};

export const convertShape = (shape: Shape, factor: number): Shape => {
  return {
    ...shape,
    points: shape.points.map((point) => {
      return {
        x: point.x * factor,
        y: point.y * factor,
      };
    }),
  };
};

export const scalePolygon = (points: Point[], scale: number): Point[] => {
  const centroid = getCenter(points);

  return points.map((point) => {
    return {
      x: centroid.x + (point.x - centroid.x) * scale,
      y: centroid.y + (point.y - centroid.y) * scale,
    };
  });
};

export const getDimensions = (
  points: Point[]
): { width: number; height: number } => {
  const minX = Math.min(...points.map((point) => point.x));
  const maxX = Math.max(...points.map((point) => point.x));
  const minY = Math.min(...points.map((point) => point.y));
  const maxY = Math.max(...points.map((point) => point.y));

  return {
    width: maxX - minX,
    height: maxY - minY,
  };
};

export const getSnapPoint = (mousePosition: Point, lastPoint: Point) => {
  const angle = Math.atan2(
    mousePosition.y - lastPoint.y,
    mousePosition.x - lastPoint.x
  );
  const snapAngle = Math.round((angle * 4) / Math.PI) * (Math.PI / 4);
  const snapDistance = Math.hypot(
    mousePosition.x - lastPoint.x,
    mousePosition.y - lastPoint.y
  );
  const snapX = lastPoint.x + snapDistance * Math.cos(snapAngle);
  const snapY = lastPoint.y + snapDistance * Math.sin(snapAngle);
  return {
    x: snapX,
    y: snapY,
  };
};

export const snapSquare = (points: Point[]): Point[] => {
  const center = getCenter(points);
  const newPoints = [...points];
  for (let i = 0; i < points.length; i++) {
    const point = points[i];
    const snapPoint = snapTo45Degrees(center, point);
    newPoints[i] = snapPoint;
  }
  return newPoints;
};
