import numeral from "numeral";
import { calculateArea, getCenter, getSnapPoint } from "./math";
import { Shape } from "./RampCanvas";

interface CanvasState {
  pixelsToFeet: (pixels: number) => number;
  showMeasurements: boolean;
  pixelsToFeetRatio: number;
  activeShapes: string[];
  patterns: Record<string, CanvasPattern>;
  zoomLevel: number;
}

interface DrawingState {
  shapes: Shape[];
  currentShape?: Shape;
  highlightedShapes: string[];
  mousePosition: { x: number; y: number } | null;
  //
  canvasRef: React.RefObject<HTMLCanvasElement>;
  pixelsToFeet: (pixels: number) => number;
  showMeasurements: boolean;
  pixelsToFeetRatio: number;
  activeShapes: string[];
  patterns: Record<string, CanvasPattern>;
  isShiftPressed: boolean;
  zoomLevel: number;
}

export const drawMain = (
  context: CanvasRenderingContext2D,
  drawingState: DrawingState
) => {
  const {
    shapes,
    currentShape,
    highlightedShapes,
    mousePosition,
    //
    canvasRef,
    pixelsToFeet,
    showMeasurements,
    pixelsToFeetRatio,
    activeShapes,
    patterns,
    isShiftPressed,
    zoomLevel,
  } = drawingState;
  context.clearRect(0, 0, context.canvas.width, context.canvas.height);

  const orderedShapes = [
    ...shapes.filter((shape) => !activeShapes.includes(shape.id)),
    ...shapes.filter((shape) => activeShapes.includes(shape.id)),
  ];

  orderedShapes.forEach((shape, index) => {
    const isHighlighted = highlightedShapes.includes(shape.id);
    drawShape(
      context,
      shape,
      index,
      {
        pixelsToFeet,
        showMeasurements,
        pixelsToFeetRatio,
        activeShapes,
        patterns,
        zoomLevel,
      },
      isHighlighted
    );
  });

  // ok we're done drawing all the shapes. now we need to draw the current shape
  if (!currentShape) {
    return;
  }
  context.beginPath();
  context.lineWidth = 3;
  context.strokeStyle = "blue";
  context.setLineDash(lineStyleToDash(currentShape.line_style));
  context.lineWidth = currentShape.line_width;
  currentShape.points.forEach((point, pointIndex) => {
    if (pointIndex === 0) {
      context.moveTo(point.x, point.y);
    } else {
      context.lineTo(point.x, point.y);
    }
  });
  context.stroke();

  currentShape.points.forEach((point, index) => {
    if (index === 0) {
      context.moveTo(point.x, point.y);
    } else {
      context.lineTo(point.x, point.y);
    }

    // Draw points with red fill and white outline
    drawPoint(context, point.x, point.y);
  });

  // Draw the "live" line to the current mouse position if in progress
  if (mousePosition && currentShape.points.length > 0) {
    const lastPoint = currentShape.points[currentShape.points.length - 1];
    context.beginPath();
    // if Shift is pressed, snap the line to the nearest 45 degree angle
    if (isShiftPressed) {
      const snapPoint = getSnapPoint(mousePosition, lastPoint);
      context.moveTo(lastPoint.x, lastPoint.y);
      context.lineTo(snapPoint.x, snapPoint.y);
    } else {
      context.moveTo(lastPoint.x, lastPoint.y);
      context.lineTo(mousePosition.x, mousePosition.y);
    }

    context.strokeStyle = "blue";
    context.setLineDash(lineStyleToDash(currentShape.line_style));
    context.lineWidth = currentShape.line_width;
    context.lineWidth = 2;
    context.stroke();

    // Calculate the length of the line segment
    const length = pixelsToFeet(
      Math.hypot(mousePosition.x - lastPoint.x, mousePosition.y - lastPoint.y)
    );

    if (showMeasurements) {
      // Draw the length of the line segment
      drawTextPill(
        context,
        (lastPoint.x + mousePosition.x) / 2,
        (lastPoint.y + mousePosition.y) / 2,
        `${length.toFixed(0)} ft`
      );
    }
  }

  // check if mouse position is close to a point
  if (mousePosition) {
    // convert mousePosition to canvas coordinates
    const rect = canvasRef.current?.getBoundingClientRect();
    if (!rect) {
      return;
    }
    currentShape.points.forEach((point) => {
      if (
        Math.hypot(point.x - mousePosition.x, point.y - mousePosition.y) < 10
      ) {
        drawPoint(context, point.x, point.y, null, null, "limegreen", "white");
      }
    });
  }
};

export const drawShape = (
  context: CanvasRenderingContext2D,
  shape: Shape,
  index: number,
  state: CanvasState,
  isHighlighted: boolean
) => {
  const hoveredShapeIndex = null;
  const highlightedColor = `#32cd3270`;
  const strokeStyle = isHighlighted ? highlightedColor : shape.stroke_style;
  const fillStyle = isHighlighted ? highlightedColor : shape.fill_style;
  const {
    pixelsToFeet,
    showMeasurements,
    pixelsToFeetRatio,
    activeShapes,
    patterns,
  } = state;
  if (shape.type === "Text") {
    const fontSize = Math.min(
      128,
      Math.max(12, state.zoomLevel * shape.font_size)
    );
    drawText(
      context,
      shape.points[0].x,
      shape.points[0].y,
      shape.text,
      strokeStyle,
      fontSize,
      hoveredShapeIndex === index || activeShapes.includes(shape.id)
    );
    return;
  }

  if (shape.type === "Tie Down T" || shape.type === "Double T") {
    // Draw the shape
    context.beginPath();
    context.lineWidth = 3;
    context.strokeStyle = strokeStyle;
    context.fillStyle = fillStyle;
    context.setLineDash(lineStyleToDash(shape.line_style));
    context.lineWidth = shape.line_width;
    const center = getCenter(shape.points);
    // horizontal line
    context.beginPath();
    context.moveTo(shape.points[0].x, shape.points[0].y);
    context.lineTo(shape.points[1].x, shape.points[1].y);
    context.stroke();
    // Vertical line
    context.beginPath();

    context.stroke();
    const angle = Math.atan2(
      shape.points[1].y - shape.points[0].y,
      shape.points[1].x - shape.points[0].x
    );
    const length = Math.hypot(
      shape.points[1].x - shape.points[0].x,
      shape.points[1].y - shape.points[0].y
    );
    const verticalLength = length / 2; // Adjust this value as needed
    const verticalPoint1 = {
      x: center.x - verticalLength * Math.sin(angle),
      y: center.y + verticalLength * Math.cos(angle),
    };
    const verticalPoint2 = {
      x: center.x + verticalLength * Math.sin(angle),
      y: center.y - verticalLength * Math.cos(angle),
    };
    context.moveTo(verticalPoint1.x, verticalPoint1.y);
    context.lineTo(verticalPoint2.x, verticalPoint2.y);
    context.stroke();

    // add the second line for the Double T
    if (shape.type === "Double T") {
      context.moveTo(shape.points[2].x, shape.points[2].y);
      context.lineTo(shape.points[3].x, shape.points[3].y);
      context.stroke();
    }
  }

  if (shape.type === "Circle" || shape.type === "Helipad") {
    const center = getCenter(shape.points);
    const radius =
      Math.hypot(
        shape.points[1].x - shape.points[0].x,
        shape.points[1].y - shape.points[0].y
      ) / 2;
    context.setLineDash(lineStyleToDash(shape.line_style));
    context.lineWidth = shape.line_width;
    context.strokeStyle = strokeStyle;
    context.beginPath();
    context.arc(center.x, center.y, radius, 0, 2 * Math.PI);
    context.stroke();
    if (fillStyle) {
      context.fillStyle = fillStyle;
      context.fill();
    }
  }

  // Draw the shape
  context.beginPath();
  context.lineWidth = 3;
  context.setLineDash(lineStyleToDash(shape.line_style));
  context.lineWidth = shape.line_width;
  context.strokeStyle = strokeStyle;
  context.fillStyle = fillStyle;
  shape.points.forEach((point, pointIndex) => {
    if (pointIndex === 0) {
      context.moveTo(point.x, point.y);
    } else {
      context.lineTo(point.x, point.y);
    }
  });

  // Areas should be a closed shape. And only closed shapes need a fill()
  // for Areas, we'll show the sqft of the area
  if (shape.type === "Area") {
    context.closePath();
    if (fillStyle || shape.fill_pattern) {
      if (fillStyle) {
        context.fillStyle = fillStyle;
      } else {
        context.fillStyle = patterns[shape.fill_pattern];
      }
      context.fill();
    }
    context.stroke();
    if (
      !shape.locked &&
      (hoveredShapeIndex === index || activeShapes.includes(shape.id))
    ) {
      const area = calculateArea(shape.points, 1 / pixelsToFeetRatio);
      if (showMeasurements) {
        const center = getCenter(shape.points);
        drawTextPill(
          context,
          center.x,
          center.y,
          `${numeral(area).format("0,")} ft²`
        );
      }
    }
  }

  if (
    shape.type === "Tie Down T" ||
    shape.type === "Double T" ||
    shape.type === "Circle" ||
    shape.type === "Helipad"
  ) {
    context.closePath();
  }
  if (
    shape.type !== "Tie Down T" &&
    shape.type !== "Double T" &&
    shape.type !== "Circle" &&
    shape.type !== "Helipad"
  ) {
    context.stroke();
  }

  // Draw the text if the shape has any
  if (shape.text) {
    const center = getCenter(shape.points);

    // we want the "H" for the Helipad to take up 80% of the width/height of the shape
    if (shape.type === "Helipad") {
      const segmentSize = Math.hypot(
        shape.points[1].x - shape.points[0].x,
        shape.points[1].y - shape.points[0].y
      );
      const fontSize = 0.8 * segmentSize;
      drawText(context, center.x, center.y, shape.text, strokeStyle, fontSize);
    } else {
      const fontSize = Math.min(
        128,
        Math.max(12, state.zoomLevel * shape.font_size)
      );
      drawText(context, center.x, center.y, shape.text, strokeStyle, fontSize);
    }
  }

  // Annotate the line segments with their lengths
  if (hoveredShapeIndex === index || activeShapes.includes(shape.id)) {
    shape.points.forEach((point, pointIndex) => {
      // this is the "closing" segment of a shape. we don't need to draw the length
      // for tha tone since the Line is not a closed form.
      if (
        (shape.type === "Line" ||
          shape.type === "Tie Down T" ||
          shape.type === "Double T" ||
          shape.type === "Circle" ||
          shape.type === "Helipad") &&
        pointIndex === shape.points.length - 1
      ) {
        return;
      }

      const nextPoint = shape.points[(pointIndex + 1) % shape.points.length];
      const length = pixelsToFeet(
        Math.hypot(nextPoint.x - point.x, nextPoint.y - point.y)
      );
      if (showMeasurements) {
        if (shape.type === "Circle" && pointIndex !== 1) {
          return;
        }
        if (shape.type === "Helipad" && pointIndex !== 1) {
          return;
        }
        if (shape.type === "Tie Down T" && pointIndex !== 2) {
          return;
        }
        if (shape.type === "Double T" && pointIndex !== 2) {
          return;
        }
        if (!shape.locked) {
          drawTextPill(
            context,
            (point.x + nextPoint.x) / 2,
            (point.y + nextPoint.y) / 2,
            `${length.toFixed(0)} ft`
          );
        }
      }
    });
  }

  // Draw points with red fill and white outline
  if (
    !shape.locked &&
    (hoveredShapeIndex === index || activeShapes.includes(shape.id))
  ) {
    shape.points.forEach((point) => drawPoint(context, point.x, point.y));
  }
};

export const drawPoint = (
  context: CanvasRenderingContext2D,
  x: number,
  y: number,
  radius: number = 6,
  lineWidth: number = 2,
  fillStyle: string = "red",
  strokeStyle: string = "white"
) => {
  context.fillStyle = fillStyle;
  context.strokeStyle = strokeStyle;
  context.lineWidth = lineWidth;
  context.setLineDash([]);
  context.beginPath();
  context.arc(x, y, radius, 0, Math.PI * 2); // 6px radius for the point
  context.fill();
  context.stroke();
};

export const measureText = (
  context: CanvasRenderingContext2D,
  shape: Shape
) => {
  const text = shape.text;
  const fontSize = shape.font_size;
  context.font = `${fontSize}px Lexend Deca`;
  const metrics = context.measureText(text);
  return metrics;
};

export const drawText = (
  context: CanvasRenderingContext2D,
  midX: number,
  midY: number,
  text: string,
  color: string = "black",
  fontSize: number = 12,
  border: boolean = false
) => {
  const padding: number = 10;

  context.font = `${fontSize}px Lexend Deca`;
  context.fillStyle = color;

  // Measure text width
  const textMetrics = context.measureText(text);
  const textWidth = textMetrics.width;

  // Calculate text height (approximation using font size)
  const textHeight = fontSize;

  // Adjust position to center text
  const x = midX - textWidth / 2;
  const y = midY + textHeight / 3; // Adjust to align the vertical center

  // Calculate box dimensions with padding
  const boxX = x - padding;
  const boxY = y - textHeight - padding;
  const boxWidth = textWidth + padding * 2;
  const boxHeight = textHeight + padding * 2;

  if (border) {
    // Draw box border
    context.strokeStyle = "blue";
    context.strokeRect(boxX, boxY, boxWidth, boxHeight);
  }

  // Draw the text
  context.fillStyle = color;
  context.fillText(text, x, y);
};

export const drawTextPill = (
  context: CanvasRenderingContext2D,
  midX: number,
  midY: number,
  text: string,
  fontSize: number = 12
) => {
  // Draw a rounded rectangle for the distance box
  const padding = 4;
  context.font = `${fontSize}px Lexend Deca`;
  const textWidth = context.measureText(text).width;
  const boxWidth = textWidth + padding * 2;
  const boxHeight = fontSize + padding * 2;
  const radius = 5;

  context.fillStyle = "white";
  context.strokeStyle = "black";
  context.lineWidth = 1;

  context.beginPath();
  context.moveTo(midX - boxWidth / 2 + radius, midY - boxHeight / 2);
  context.lineTo(midX + boxWidth / 2 - radius, midY - boxHeight / 2);
  context.quadraticCurveTo(
    midX + boxWidth / 2,
    midY - boxHeight / 2,
    midX + boxWidth / 2,
    midY - boxHeight / 2 + radius
  );
  context.lineTo(midX + boxWidth / 2, midY + boxHeight / 2 - radius);
  context.quadraticCurveTo(
    midX + boxWidth / 2,
    midY + boxHeight / 2,
    midX + boxWidth / 2 - radius,
    midY + boxHeight / 2
  );
  context.lineTo(midX - boxWidth / 2 + radius, midY + boxHeight / 2);
  context.quadraticCurveTo(
    midX - boxWidth / 2,
    midY + boxHeight / 2,
    midX - boxWidth / 2,
    midY + boxHeight / 2 - radius
  );
  context.lineTo(midX - boxWidth / 2, midY - boxHeight / 2 + radius);
  context.quadraticCurveTo(
    midX - boxWidth / 2,
    midY - boxHeight / 2,
    midX - boxWidth / 2 + radius,
    midY - boxHeight / 2
  );
  context.closePath();
  context.fill();
  context.stroke();

  context.fillStyle = "black";
  context.fillText(text, midX - textWidth / 2, midY + fontSize / 2 - padding);
};

export const lineStyleToDash = (lineStyle: string) => {
  switch (lineStyle) {
    case "dashed":
      return [5, 5];
    case "dotted":
      return [2, 2];
    case "solid":
    default:
      return [];
  }
};

export const createImagePattern = async (
  canvas: HTMLCanvasElement,
  imagePath: string,
  alpha: number = 1.0 // Alpha value between 0 (fully transparent) and 1 (fully opaque)
): Promise<CanvasPattern | null> => {
  return new Promise((resolve, reject) => {
    const context = canvas.getContext("2d");
    const image = new Image();
    image.src = imagePath;

    image.onload = () => {
      // Create an offscreen canvas
      const offscreenCanvas = document.createElement("canvas");
      offscreenCanvas.width = image.width;
      offscreenCanvas.height = image.height;
      const offscreenContext = offscreenCanvas.getContext("2d");

      if (offscreenContext) {
        // Draw the image onto the offscreen canvas
        offscreenContext.globalAlpha = alpha; // Set alpha transparency
        offscreenContext.drawImage(image, 0, 0);

        // Create a pattern from the offscreen canvas
        const pattern = context.createPattern(offscreenCanvas, "repeat");
        if (pattern) {
          resolve(pattern);
        } else {
          reject(new Error("Failed to create pattern"));
        }
      } else {
        reject(new Error("Failed to get context for offscreen canvas"));
      }
    };

    image.onerror = () => {
      reject(new Error(`Failed to load image at ${imagePath}`));
    };
  });
};
