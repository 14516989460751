import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { HangarsStateContainer } from "../../../../containers/HangarsStateContainer";
import { RampsStateContainer } from "../../../../containers/RampsStateContainer";
import { RampStateContainer } from "../../../../containers/RampStateContainer";
import { RampEditorPresenter } from "./RampEditorPresenter";

type Props = {
  ramp_id: string;
  onClose: () => void;
};

export const RampEditor: React.FC<Props> = ({ ramp_id, ...props }) => {
  return (
    <HangarsStateContainer isReference={false}>
      <RampsStateContainer isReference={false}>
        <RampStateContainer
          initialState={{
            ramp_id,
            isReference: false,
            includeReferenceImage: true,
          }}
        >
          <RampEditorPresenter {...props} />
        </RampStateContainer>
      </RampsStateContainer>
    </HangarsStateContainer>
  );
};

// Wrapper to use useParams and pass ramp_id to RampEditor
export const RampEditorWrapper = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Extract the :id from the URL

  return (
    <Box p={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" justifyContent="flex-start" alignItems="center">
          <IconButton onClick={() => navigate(`/settings?tab=Ramps`)}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="subtitle2">Edit Ramp</Typography>
        </Stack>
        {/* <IconButton>
          <HelpIcon />
        </IconButton> */}
      </Stack>
      <RampEditor
        ramp_id={id!} // Pass the id as a prop
        onClose={() => navigate(`/settings?tab=Ramps`)}
      />
    </Box>
  );
};
