import BoltIcon from "@mui/icons-material/Bolt";
import DoneIcon from "@mui/icons-material/Done";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { round, sum } from "lodash";
import * as React from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useActiveFBOs } from "../../containers/ActiveFBOContainer";
import { HangarStateContainer } from "../../containers/HangarStateContainer";
import { useHangarsState } from "../../containers/HangarsStateContainer";
import { useIdentity } from "../../containers/IdentityContainer";
import { usePostgrest } from "../../hooks";
import { CornerStrategy, Hangar, ParamSet, Preference } from "../../types";
import { calcStackUtilization } from "../../utils";
import { LinearProgressWithLabel } from "../../widgets/LinearProgressWithLabel";
import { HangarStack } from "../Hangar";
import {
  CustomPlacementOptions,
  CustomStackDialog,
} from "../Hangar/CustomStackDialog";
import { EvaluatingStackDialog } from "../Hangar/EvaluatingStackDialog";
import { generateDevParamSets } from "../Hangar/GenerateParamSets";
import { LayoutOption, runParamSets } from "../Hangar/RunParamSet";

type Props = {};

type HangarResult = {
  id: string;
  hangar: Hangar;
  layoutOptions: LayoutOption[];
  progress: number[];
  time: number;
};

const calculateStackedAirplanes = (result: HangarResult): number[] => {
  return result.layoutOptions.map((layoutOption) => {
    return layoutOption.stackMembers.filter((s) => s.x !== null).length;
  });
};

const calculateStackUtilizations = (result: HangarResult): number[] => {
  return result.layoutOptions.map((layoutOption) => {
    return calcStackUtilization(result.hangar, {
      id: uuidv4(),
      options: layoutOption.paramSet.options,
      tenants: layoutOption.stackMembers.map((stackMember) => ({
        ...result.hangar.stack.tenants.find(
          (t) => t.placement_id === stackMember.placement_id
        ),
        selected: false, // overwrite or keep?
        position: {
          ...result.hangar.stack.tenants.find(
            (t) => t.placement_id === stackMember.placement_id
          ).position,
          x: stackMember.x,
          y: stackMember.y,
          angle: stackMember.angle,
        },
      })),
      isReference: false,
      movableObstacles: [],
    });
  });
};

const convertLayoutToHangarStacks = (result: HangarResult): HangarStack[] => {
  return result.layoutOptions.map((layoutOption) => {
    return {
      ...layoutOption,
    };
  });
};

const PLACEMENT_OPTIONS_KEYS = [
  "wall_spacing_left",
  "wall_spacing_back",
  "wall_spacing_right",
  "horizontal_spacing",
  "vertical_spacing",
  "overlap",
  "ignore_nose_in_out",
  "ignore_front_back",
  "ignore_outside_hangar",
  "density_threshold",
  "tug_spacing",
  "sample_size",
  "corner_strategies",
  "cloud_position_prioritization",
];

const ResultGrid: React.FC<{ id: string; results: HangarResult[] }> = ({
  id,
  results,
}) => {
  const customPlacementColumns: GridColDef[] = PLACEMENT_OPTIONS_KEYS.map(
    (option) => ({
      field: option,
      headerName: `option_${option}`,
      width: 80,
      valueGetter: (_, row) => {
        return row?.layoutOptions?.[0]?.paramSet?.options?.[option];
      },
    })
  );
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "",
      width: 40,
      renderCell: (params: GridRenderCellParams) => {
        const totalProgress = params.row.layoutOptions.length
          ? sum(params.row.progress) / params.row.layoutOptions.length
          : 0;
        if (totalProgress === 1) {
          return <DoneIcon />;
        }

        return "";
      },
    },
    {
      field: "hangar_name",
      headerName: "Hangar",
      width: 200,
      valueGetter: (_, row) => row?.hangar?.name,
    },
    {
      field: "progress",
      headerName: "Progress",
      width: 200,
      valueGetter: (_, row) => 100 * sum(row?.progress),
      renderCell: (params: GridRenderCellParams) => (
        <LinearProgressWithLabel
          value={
            params.row.layoutOptions.length
              ? (100 * sum(params.row.progress)) /
                params.row.layoutOptions.length
              : 0
          }
          sx={{ width: "100%" }}
        />
      ),
    },
    {
      field: "n_variations",
      headerName: "# Variants",
      width: 150,
      valueGetter: (_, row) => row.layoutOptions.length,
    },
    {
      field: "n_placed",
      headerName: "# Placed",
      width: 150,
      valueGetter: (_, row) =>
        row.layoutOptions.length
          ? `${Math.max(...calculateStackedAirplanes(row))}`
          : "---",
    },
    {
      field: "time",
      headerName: "Duration",
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="inherit">
          {params.row.time > 0 ? `${round(params.row.time / 1000, 1)}s` : "---"}
        </Typography>
      ),
    },
    {
      field: "util",
      headerName: "Utilization",
      width: 150,
      valueGetter: (_, row) =>
        row.layoutOptions.length
          ? Math.max(...calculateStackUtilizations(row))
          : "---",
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="inherit">
          {params.row.layoutOptions.length
            ? `${Math.max(...calculateStackUtilizations(params.row))}%`
            : "---"}
        </Typography>
      ),
    },
    {
      field: "utils",
      headerName: "Top 4 Utilizations",
      width: 200,
      valueGetter: (_, row) =>
        row.layoutOptions.length
          ? calculateStackUtilizations(row)
              .sort((a, b) => b - a)
              .slice(0, 4)
              .join(", ")
          : "---",
    },
    {
      field: "action",
      headerName: "",
      sortable: false,
      width: 200,
      valueGetter: (_, row) => `${window.location.origin}/#/stackmaster/${id}`,
      renderCell: (params: GridRenderCellParams) => {
        const [open, setOpen] = React.useState<boolean>(false);
        return (
          <Stack direction="row" spacing={1}>
            <Button
              size="small"
              variant="contained"
              color="success"
              onClick={() => setOpen(true)}
            >
              View Results
            </Button>
            <HangarStateContainer
              initialState={{
                hangar_id: params.row.hangar.id,
                isReference: false,
              }}
            >
              <EvaluatingStackDialog
                isAlgorithmTesting={true}
                open={open}
                status={"complete"}
                stacks={convertLayoutToHangarStacks(params.row)}
                progress={params.row.progress}
                onClose={() => setOpen(false)}
                hangar={params.row.hangar}
                hangarId={""}
              />
            </HangarStateContainer>
          </Stack>
        );
      },
    },
    ...customPlacementColumns,
  ];
  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <DataGrid
        getRowId={(row) => row.hangar.id}
        rows={results}
        columns={columns}
        pageSizeOptions={[20]}
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export const StackMasterPresenter: React.FC<Props> = ({ ...props }) => {
  const { id: idFromRoute } = useParams();
  const id = idFromRoute ?? uuidv4();
  const { airplxIdentity, airplxToken } = useIdentity();
  const postgres = usePostgrest();
  const [results, setResults] = React.useState<HangarResult[]>([]);
  const { activeFBO } = useActiveFBOs();
  const { hangars: allHangars } = useHangarsState();
  const [openCustomStackDialog, setOpenCustomStackDialog] = React.useState<
    boolean
  >(false);

  const hangars = React.useMemo(() => {
    return allHangars.filter((hangar) => hangar.name.startsWith("Speed"));
  }, [allHangars]);

  /**
   * 1. Select hangars to run. Or just run all of them
   * 2. Click button for parameters prompt
   * 3. Run 1 hangar at a time.
   * 4. Save results to a table of some kind.
   */
  React.useEffect(() => {
    (async () => {
      if (idFromRoute) {
        const res = await postgres
          .from("algorithm_test_result")
          .select()
          .eq("id", id)
          .single();
        setResults(res.data.result);
        return;
      }
      setResults(
        hangars.map((hangar) => ({
          id: uuidv4(),
          hangar,
          layoutOptions: [],
          progress: [0],
          time: 0,
        }))
      );
    })();
  }, [hangars]);

  const onClickRun = React.useCallback(
    async (customPlacementOptions: CustomPlacementOptions) => {
      const currentResults: HangarResult[] = hangars.map((hangar) => ({
        id: uuidv4(),
        hangar,
        layoutOptions: [],
        progress: [0],
        time: 0,
      }));
      for (const hangar of hangars) {
        const startTime = +new Date();
        console.log(`running hangar: ${hangar.name}`);
        const paramSet: ParamSet = {
          run_id: uuidv4(),
          label: "base case",
          // slim down the hangar for the API call
          hangar: {
            id: hangar.id,
            width: hangar.width,
            depth: hangar.depth,
            left_door: hangar.left_door,
            right_door: hangar.right_door,
            garage_doors: hangar.garageDoors ?? [],
          },
          // slim down the tenant so it's just what we need to do the placement
          aircrafts_to_place: hangar.stack.tenants
            .filter(
              (t) => t.position.preferences?.indexOf(Preference.LOCKED) === -1
            )
            .map((tenant) => ({
              id: tenant.aircraft.id,
              placement_id: tenant.placement_id,
              angles_to_try: [],
              sectors_to_try: null,
              preferences: tenant.position.preferences,
            })),
          locked_aircrafts: hangar.stack.tenants
            .filter(
              (t) => t.position.preferences?.indexOf(Preference.LOCKED) > -1
            )
            .map((tenant) => ({
              id: tenant.aircraft.id,
              placement_id: tenant.placement_id,
              x: tenant.position.x,
              // hangar.depth - y
              // y: tenant.position.y - hangar.depth,
              y: tenant.position.y,
              angle: tenant.position.angle,
            })),
          obstacles: hangar.obstacles ?? [],
          options: {
            ...customPlacementOptions,
            corner_strategy: [CornerStrategy.ANGLE_45, CornerStrategy.ANGLE_45],
            tug_spacing: 0, // obselete
            error_type: null, // obselete
            density_threshold: 0.8, // obselete
          },
        };
        const paramSets = generateDevParamSets(
          paramSet,
          hangar.stack.tenants.map((t) => t.aircraft),
          customPlacementOptions
        );
        await runParamSets(
          hangar,
          paramSets,
          airplxIdentity,
          airplxToken,
          (layoutOptions, progress) => {
            const idx = currentResults.findIndex(
              (r) => r.hangar.id === hangar.id
            );
            currentResults[idx] = {
              ...currentResults[idx],
              layoutOptions,
              progress,
              time: +new Date() - startTime,
            };
            setResults([...currentResults]);
          }
        );
      }
      // all done, save this bad boy to the db
      await postgres.from("algorithm_test_result").insert({
        // bring out the id for ease of lookup
        id,
        // everything we stuff into the result so we can bring it back
        // out to load the page if need be
        result: currentResults,
      });
    },
    [hangars]
  );

  return (
    <Box sx={{ ml: 2, mr: 2, height: "100%" }}>
      <Stack direction="column" sx={{ height: "100%" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ mt: 2, mb: 2 }}
        >
          <Typography variant="h6">Benchmarking Hangars</Typography>
          <Button
            startIcon={<BoltIcon />}
            variant="contained"
            onClick={() => setOpenCustomStackDialog(true)}
          >
            Stack
          </Button>
        </Stack>
        <Divider />
        <ResultGrid id={id} results={results} />
      </Stack>
      {openCustomStackDialog && (
        <CustomStackDialog
          isDev
          onClickRunStack={(customPlacementOptions: CustomPlacementOptions) => {
            onClickRun(customPlacementOptions);
            setOpenCustomStackDialog(false);
          }}
          onClose={() => {
            setOpenCustomStackDialog(false);
          }}
        />
      )}
    </Box>
  );
};
