import { Divider, Stack, Switch, Typography } from "@mui/material";
import * as React from "react";
import { FBOSettings } from "../../../types";

type Props = {
  settings: FBOSettings;
  setSettings: (settings: FBOSettings) => void;
};

export const ConfigurationPresenter: React.FC<Props> = ({
  settings,
  setSettings,
}) => {
  const permissions = [
    {
      field: "displayEstimatedValue",
      label: "Display Estimated Value",
      description: "Display estimated value on the dashboard",
    },
    {
      field: "addTeamMembers",
      label: "Add Team Members",
      description: "Add team members to the FBO",
    },
    {
      field: "modifyTenants",
      label: "Modify Tenants",
      description: "Modify tenant information",
    },
  ];

  return (
    <Stack direction="column" spacing={4} p={2}>
      {/* new permissions */}
      <Stack direction="column" spacing={1}>
        <Typography variant="subtitle1">Role Permissions</Typography>
        <Typography variant="subtitle2" color="textSecondary">
          Toggle permissions for each user role. These permissions will be
          applied to all users in each role.
        </Typography>
        <Stack direction="row" spacing={2} alignItems="center" width={500}>
          {/* Permissions Column */}
          {/* Manager Column */}
          <Stack direction="column" spacing={2}>
            <Typography variant="subtitle1" fontWeight="bold" align="right">
              Manager
            </Typography>
            {permissions.map((permission, index) => (
              <Stack
                key={index}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                width={400}
              >
                <Stack direction="column">
                  <Typography variant="body1">{permission.label}</Typography>
                  {/* <Typography variant="body2" color="textSecondary">
                    {permission.description}
                  </Typography> */}
                </Stack>
                <Switch
                  key={index}
                  onChange={(evt) => {
                    setSettings({
                      ...settings,
                      [`${permission.field}ForManager`]: evt.target.checked,
                    });
                  }}
                  checked={settings[`${permission.field}ForManager`] ?? true}
                />
              </Stack>
            ))}
          </Stack>
          <Divider orientation="vertical" flexItem />
          {/* Operator Column */}
          <Stack direction="column" spacing={2} alignItems="center">
            <Typography variant="subtitle1" fontWeight="bold">
              Operator
            </Typography>
            {permissions.map((permission, index) => (
              <Switch
                key={index}
                onChange={(evt) => {
                  setSettings({
                    ...settings,
                    [`${permission.field}ForOperator`]: evt.target.checked,
                  });
                }}
                checked={settings[`${permission.field}ForOperator`] ?? true}
              />
            ))}
          </Stack>
        </Stack>
      </Stack>
      {/* end new permissions */}
      {/* <Stack direction="column">
        <Typography>Display Estimated Value?</Typography>
        <Stack direction="column">
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                onChange={(evt) => {
                  setSettings({
                    ...settings,
                    displayEstimatedValueForManager: evt.target.checked,
                  });
                }}
                checked={settings?.displayEstimatedValueForManager ?? true}
              />
            }
            label="Managers"
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                onChange={(evt) => {
                  setSettings({
                    ...settings,
                    displayEstimatedValueForOperator: evt.target.checked,
                  });
                }}
                checked={settings?.displayEstimatedValueForOperator ?? true}
              />
            }
            label="Operators"
          />
        </Stack>
      </Stack> */}
      <Divider />
      <Stack direction="column" spacing={1}>
        <Typography variant="subtitle1">Global Permissions</Typography>
        <Typography variant="subtitle2" color="textSecondary">
          Toggle global permissions. These will be applied to all users,
          regardless of role.
        </Typography>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{ width: "100%" }}
        >
          <Typography>
            Ignore Dead Space in Utilization Calculations?
          </Typography>
          <Switch
            checked={settings?.ignoreDeadSpaceForUtilization ?? false}
            onChange={(evt) =>
              setSettings({
                ...settings,
                ignoreDeadSpaceForUtilization: evt.target.checked,
              })
            }
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
