import { Box, Stack } from "@mui/material";
import * as React from "react";
import { create } from "zustand";
import { useRampState } from "../../../../containers/RampStateContainer";
import { useRampsState } from "../../../../containers/RampsStateContainer";
import { useSnackbar } from "../../../../containers/SnackbarContainer";
import { Ramp } from "../../../../types";
import { RampCanvas, Shape } from "./RampCanvas/RampCanvas";
import { RampEditorForm } from "./RampEditorForm";

interface RampEditorState {
  showReferenceImage: boolean;
  setShowReferenceImage: (show: boolean) => void;
  showMeasurements: boolean;
  setShowMeasurements: (show: boolean) => void;
  useV2: boolean;
  setUseV2: (useV2: boolean) => void;
}

export const useRampEditorState = create<RampEditorState>(
  (set): RampEditorState => ({
    showReferenceImage: true,
    setShowReferenceImage: (showReferenceImage: boolean) =>
      set({ showReferenceImage }),
    showMeasurements: true,
    setShowMeasurements: (showMeasurements: boolean) =>
      set({ showMeasurements }),
    useV2: false,
    setUseV2: (useV2: boolean) => set({ useV2 }),
  })
);

type Props = {
  onClose: () => void;
};

interface LocalRampState {
  ramp: Ramp | undefined;
  setRamp: (ramp: Ramp) => void;
  //
  activeShapes: string[];
  setActiveShapes: (activeShapes: string[]) => void;
  getActiveShapes: () => Shape[];
  //
  highlightedShapes: string[];
  setHighlightedShapes: (highlightedShapes: string[]) => void;
}

export const useLocalRampState = create<LocalRampState>(
  (set, get): LocalRampState => ({
    ramp: undefined,
    setRamp: (ramp: Ramp) => set({ ramp }),
    //
    activeShapes: [],
    setActiveShapes: (activeShapes: string[]) => set({ activeShapes }),
    getActiveShapes() {
      return get().ramp.shapes.filter((s) => get().activeShapes.includes(s.id));
    },
    //
    highlightedShapes: [],
    setHighlightedShapes: (highlightedShapes: string[]) =>
      set({ highlightedShapes }),
  })
);

export const RampEditorPresenter: React.FC<Props> = ({ onClose }) => {
  const { ramps, setRamps } = useRampsState();
  const { ramp, setRamp } = useRampState();
  const { setRamp: setLocalRamp, ramp: localRamp } = useLocalRampState();
  const [selectedTool, setSelectedTool] = React.useState<string>("Select");
  const [showReferenceImage, setShowReferenceImage] = React.useState<boolean>(
    true
  );
  const snackbar = useSnackbar();

  React.useEffect(() => {
    setLocalRamp(ramp);
  }, [ramp]);

  const handleClose = () => {
    onClose();
  };

  const onClickSaveRamp = () => {
    setRamp(localRamp);
    // setRamps(ramps.map((r) => (r.id === localRamp.id ? localRamp : r)));
    snackbar.notify({ text: "Ramp changes saved.", severity: "success" });
  };
  /**
   * make a ramp
   * give it a name
   * upload an image
   * make a reference line to tell us how many pixels = how many feet
   * add an outline
   * add other markings
   */

  if (!localRamp) {
    return null;
  }

  return (
    <Stack
      direction="row"
      spacing={8}
      alignItems="flex-start"
      width="100%"
      height="100%"
    >
      <Box
        sx={{
          pt: 1,
          width: 250,
          position: "sticky", // Makes the Box sticky
          top: 0, // Stick to the top of the parent container
          alignSelf: "flex-start", // Align properly within the parent
        }}
      >
        <RampEditorForm
          ramp={localRamp}
          setRamp={setLocalRamp}
          onClickSaveRamp={onClickSaveRamp}
          onClose={onClose}
          selectedTool={selectedTool}
          setSelectedTool={setSelectedTool}
        />
      </Box>
      <Stack
        direction="column"
        spacing={1}
        width="100%"
        sx={{
          overflowY: "auto", // Allow vertical scrolling for this section
          maxHeight: "100vh", // Limit height to viewport for scrollable content
        }}
      >
        <RampCanvas
          ramp={localRamp}
          setRamp={setLocalRamp}
          selectedTool={selectedTool}
          setSelectedTool={setSelectedTool}
        />
      </Stack>
    </Stack>
  );
};
