import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  Dialog,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import React = require("react");

type Props = {
  name: string;
  onChange: (name: string) => void;
};

export const EditFboName: React.FC<Props> = ({ name, onChange }) => {
  const [open, setOpen] = useState(false);
  const [newName, setNewName] = useState<string>(name);

  return (
    <>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography width={150}>{name}</Typography>
        <IconButton onClick={() => setOpen(true)}>
          <EditIcon />
        </IconButton>
      </Stack>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Typography variant="h6" sx={{ p: 2 }}>
          Edit FBO Name
        </Typography>
        <Stack direction="column" spacing={2} sx={{ p: 2 }}>
          <TextField
            label="Name"
            value={newName}
            onChange={(evt) => setNewName(evt.target.value)}
          />
          <Stack direction="row" spacing={1} justifyContent="flex-end">
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                setOpen(false);
                onChange(newName);
              }}
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
};
