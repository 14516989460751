import * as React from "react";
import { useAsync } from "react-use";
import { useApi } from "../../../../../containers/ApiContainer";
import { Ramp } from "../../../../../types";
import Canvas from "../../../../../widgets/useCanvas";

import { drawMain } from "./drawing";
import { usePatterns } from "./patterns/usePatterns";

type Props = {
  ramp: Ramp;
  width: number;
  showReferenceImage: boolean;
  zoomLevel: number;
};

export const RampCanvasReadOnlyNew: React.FC<Props> = ({
  ramp,
  width,
  showReferenceImage,
  zoomLevel,
}) => {
  const { postgrest } = useApi();

  const rampImage = useAsync(async () => {
    if (ramp.id && showReferenceImage) {
      const { data } = await postgrest
        .from("ramp")
        .select("reference_image")
        .eq("id", ramp.id)
        .single();
      return data.reference_image;
    }
    return null;
  }, [ramp.reference_image]);

  const canvasRef = React.createRef<HTMLCanvasElement>();
  const canvasWidth = width;
  const canvasHeight = canvasWidth / (ramp.width / ramp.depth);
  const pixelsToFeetRatio = canvasHeight / (ramp?.depth ?? 1);
  const pixelsToFeet = (px: number) => px / pixelsToFeetRatio;

  const { loading: loadingPatterns, patterns } = usePatterns();

  // convert shapes to pixels
  const shapes = React.useMemo(
    () =>
      ramp.shapes.map((shape) => ({
        ...shape,
        points: shape.points.map((point) => ({
          x: point.x * pixelsToFeetRatio,
          y: point.y * pixelsToFeetRatio,
        })),
      })),
    [ramp.shapes, pixelsToFeetRatio]
  );

  const draw = React.useCallback(
    (context: CanvasRenderingContext2D) => {
      drawMain(context, {
        shapes,
        currentShape: null,
        highlightedShapes: [],
        mousePosition: null,
        canvasRef,
        pixelsToFeet,
        showMeasurements: false,
        pixelsToFeetRatio,
        activeShapes: [],
        patterns,
        isShiftPressed: false,
        zoomLevel,
      });
    },
    [shapes, canvasRef, pixelsToFeet, pixelsToFeetRatio, patterns]
  );

  React.useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext("2d");
    if (canvas && context) {
      draw(context);
    }
  }, [canvasRef, draw]);

  if (loadingPatterns) {
    return null;
  }

  return (
    <Canvas
      ref={canvasRef as React.RefObject<HTMLCanvasElement>}
      draw={draw}
      width={canvasWidth}
      height={canvasHeight}
      style={{
        backgroundImage: showReferenceImage && `url(${rampImage?.value})`,
        backgroundSize: showReferenceImage && "cover",
      }}
    />
  );
};
