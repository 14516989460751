import SearchIcon from "@mui/icons-material/Search";
import { Button, InputAdornment, Stack, TextField } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { round } from "lodash";
import * as React from "react";
import { useActiveFBOs } from "../../containers/ActiveFBOContainer";
import { useSnackbar } from "../../containers/SnackbarContainer";
import { PredefinedObstacle } from "../../hooks/usePredefinedObstacle";
import { Hangar, Ramp, Stack as StackType } from "../../types";
import { uuidv4 } from "../../utils";

type Props = {
  movableObstacles: PredefinedObstacle[];
  location: Hangar | Ramp;
  stack: StackType;
  setStack: (stack: StackType) => void;
};

export const MovableObstacleGrid: React.FC<Props> = ({
  movableObstacles,
  location,
  stack,
  setStack,
}) => {
  const [filter, setFilter] = React.useState<string>();

  const movableObstaclesToDisplay = React.useMemo(() => {
    if (!filter) {
      return movableObstacles;
    }
    return movableObstacles.filter(
      (mo) => mo.name.toLocaleLowerCase().indexOf(filter) > -1
    );
  }, [movableObstacles, filter]);

  const { activeFBO } = useActiveFBOs();
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      width: 200,
      editable: false,
      sortable: false,
    },
    {
      field: "length",
      headerName: "Length (ft)",
      width: 125,
      editable: false,
      sortable: false,
      valueFormatter: (value) => (value === 0 ? "N/A" : round(value, 2)),
    },
    {
      field: "width",
      headerName: "Width (ft)",
      width: 125,
      editable: false,
      sortable: false,
      valueFormatter: (value) => (value === 0 ? "N/A" : round(value, 2)),
    },
    {
      field: "height",
      headerName: "Height (ft)",
      width: 125,
      editable: false,
      sortable: false,
      valueFormatter: (value) => (value === 0 ? "N/A" : round(value, 2)),
    },
    {
      field: "action",
      headerName: "",
      sortable: false,
      width: 200,
      renderCell: (params) => {
        const snackbar = useSnackbar();
        return (
          <Button
            size="small"
            variant="contained"
            color="info"
            onClick={() => {
              const entity_id = uuidv4();
              const placement_id = uuidv4();

              setStack({
                ...stack,
                movableObstacles: stack.movableObstacles.concat([
                  {
                    id: uuidv4(),
                    fbo_id: activeFBO.id,
                    entity_id,
                    obstacle_id: params.row.id,
                    obstacle: params.row,
                    selected: false,
                    placement_id,
                    position: {
                      id: placement_id,
                      stack_id: stack.id,
                      entity_id,
                      x: 0,
                      y: 0,
                      angle: 0,
                      preferences: [],
                    },
                  },
                ]),
              });
              snackbar.notify({
                text: `${params.row.name} added to ${location.name}`,
                severity: "success",
              });
            }}
          >
            Add
          </Button>
        );
      },
    },
  ];

  return (
    <Stack
      direction="column"
      spacing={1}
      sx={{ height: "100%", width: "100%" }}
    >
      <TextField
        fullWidth
        placeholder="Search for an obstacle type by name"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={(evt) => setFilter(evt.target.value)}
      />
      <DataGrid
        disableColumnFilter
        disableRowSelectionOnClick
        disableColumnMenu
        disableColumnSelector
        rows={movableObstaclesToDisplay}
        columns={columns}
      />
    </Stack>
  );
};
