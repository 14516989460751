import { ClickAwayListener } from "@mui/material";
import { round } from "lodash";
import * as React from "react";
import { useKeyPress } from "react-use";
import { useMeasuringToolState } from "../../containers/MeasuringToolContainer";
import { ClearCanvasOverlay } from "../ClearCanvasOverlay/ClearCanvasOverlay";

const handleDrawLineWithTicksAndDistance = (
  ctx: CanvasRenderingContext2D,
  startX: number,
  startY: number,
  endX: number,
  endY: number,
  distance: string,
  isShiftPressed: boolean
) => {
  // Adjust endX and endY to make the line straight if shift is pressed
  if (isShiftPressed) {
    if (Math.abs(endX - startX) > Math.abs(endY - startY)) {
      endY = startY;
    } else {
      endX = startX;
    }
  }
  // Draw the main line
  ctx.strokeStyle = "black";
  ctx.lineWidth = 2;
  ctx.beginPath();
  ctx.moveTo(startX, startY);
  ctx.lineTo(endX, endY);
  ctx.stroke();

  // Calculate the angle of the line
  const dx = endX - startX;
  const dy = endY - startY;
  const length = Math.sqrt(dx * dx + dy * dy);
  const unitX = dx / length;
  const unitY = dy / length;

  // Perpendicular vector
  const perpX = -unitY;
  const perpY = unitX;

  // Length of the tick
  const tickLength = 10;

  // Draw tick at the start of the line
  ctx.beginPath();
  ctx.moveTo(startX + perpX * tickLength, startY + perpY * tickLength);
  ctx.lineTo(startX - perpX * tickLength, startY - perpY * tickLength);
  ctx.stroke();

  // Draw tick at the end of the line
  ctx.beginPath();
  ctx.moveTo(endX + perpX * tickLength, endY + perpY * tickLength);
  ctx.lineTo(endX - perpX * tickLength, endY - perpY * tickLength);
  ctx.stroke();

  // Calculate the midpoint of the line for the distance label
  const midX = (startX + endX) / 2;
  const midY = (startY + endY) / 2;

  // Set font and styles for the distance box
  const fontSize = 16;
  ctx.font = `${fontSize}px Lexend Deca`;
  ctx.fillStyle = "white";
  ctx.strokeStyle = "black";
  ctx.lineWidth = 1;

  // Calculate text width and height
  const textWidth = ctx.measureText(distance).width;
  const padding = 10;
  const boxWidth = textWidth + padding * 2;
  const boxHeight = fontSize + padding * 1.5;

  // Determine if the label should be inline or off to the side
  if (length > boxHeight * 2) {
    // Inline positioning
    const boxX = midX - boxWidth / 2;
    const boxY = midY - boxHeight / 2;

    // Draw a rounded rectangle for the distance box (inline)
    const radius = 5;
    ctx.beginPath();
    ctx.moveTo(boxX + radius, boxY);
    ctx.lineTo(boxX + boxWidth - radius, boxY);
    ctx.quadraticCurveTo(boxX + boxWidth, boxY, boxX + boxWidth, boxY + radius);
    ctx.lineTo(boxX + boxWidth, boxY + boxHeight - radius);
    ctx.quadraticCurveTo(
      boxX + boxWidth,
      boxY + boxHeight,
      boxX + boxWidth - radius,
      boxY + boxHeight
    );
    ctx.lineTo(boxX + radius, boxY + boxHeight);
    ctx.quadraticCurveTo(
      boxX,
      boxY + boxHeight,
      boxX,
      boxY + boxHeight - radius
    );
    ctx.lineTo(boxX, boxY + radius);
    ctx.quadraticCurveTo(boxX, boxY, boxX + radius, boxY);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();

    // Draw the distance text inside the box (inline)
    ctx.fillStyle = "black"; // Change text color to black
    ctx.fillText(distance, boxX + padding, boxY + fontSize + padding / 2);
  } else {
    // Off to the side positioning
    const offset = Math.max(50, length / 3); // Adjust offset for small distances

    // Calculate the box's top-left corner with dynamic offset
    const boxX = midX + perpX * offset - boxWidth / 2;
    const boxY = midY + perpY * offset - boxHeight / 2;

    // Draw a rounded rectangle for the distance box (off to the side)
    const radius = 5;
    ctx.beginPath();
    ctx.moveTo(boxX + radius, boxY);
    ctx.lineTo(boxX + boxWidth - radius, boxY);
    ctx.quadraticCurveTo(boxX + boxWidth, boxY, boxX + boxWidth, boxY + radius);
    ctx.lineTo(boxX + boxWidth, boxY + boxHeight - radius);
    ctx.quadraticCurveTo(
      boxX + boxWidth,
      boxY + boxHeight,
      boxX + boxWidth - radius,
      boxY + boxHeight
    );
    ctx.lineTo(boxX + radius, boxY + boxHeight);
    ctx.quadraticCurveTo(
      boxX,
      boxY + boxHeight,
      boxX,
      boxY + boxHeight - radius
    );
    ctx.lineTo(boxX, boxY + radius);
    ctx.quadraticCurveTo(boxX, boxY, boxX + radius, boxY);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();

    // Draw the distance text inside the box (off to the side)
    ctx.fillStyle = "black"; // Change text color to black
    ctx.fillText(distance, boxX + padding, boxY + fontSize + padding / 2);
  }
};

type Props = {
  children?: React.ReactNode;
  pixelsToFeetRatio: number;
  overlayExpansionRatio?: number;
};

export const MeasuringTool: React.FC<Props> = ({
  children,
  pixelsToFeetRatio,
  overlayExpansionRatio = 1.25,
  ...props
}) => {
  const {
    active: measuringToolActive,
    setActive: setMeasuringToolActive,
  } = useMeasuringToolState();

  const [measuringToolState, setMeasuringToolState] = React.useState<number>(0);
  const canvasRef = React.createRef<HTMLCanvasElement>();
  const [startPoint, setStartPoint] = React.useState<{
    x: number;
    y: number;
  } | null>(null);
  const [endPoint, setEndPoint] = React.useState<{
    x: number;
    y: number;
  } | null>(null);
  const [isShiftPressed] = useKeyPress("Shift");

  React.useEffect(() => {
    if (measuringToolActive) {
      setStartPoint(null);
      setEndPoint(null);
      setMeasuringToolState(0);
    }
  }, [measuringToolActive]);

  const handleCanvasClick = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (!measuringToolActive) {
      return;
    }
    const rect = canvasRef.current!.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    if (!startPoint) {
      // First click - store the starting point
      setStartPoint({ x, y });
      setMeasuringToolState(1);
    } else if (endPoint && measuringToolState === 1) {
      if (isShiftPressed) {
        if (
          Math.abs(endPoint.x - startPoint.x) >
          Math.abs(endPoint.y - startPoint.y)
        ) {
          setEndPoint({ x, y: startPoint.y });
        } else {
          setStartPoint({ x: startPoint.x, y });
        }
      }
      setMeasuringToolState(2);
    } else if (endPoint && measuringToolState === 2) {
      // Second click - store the end point and calculate the distance
      clearMeasurements();
      setMeasuringToolState(0);
    }
  };

  const clearMeasurements = () => {
    setStartPoint(null);
    setEndPoint(null);
    if (!canvasRef.current) {
      return;
    }
    const ctx = canvasRef.current!.getContext("2d");
    ctx?.clearRect(0, 0, canvasRef.current!.width, canvasRef.current!.height);
  };

  const draw = React.useCallback((ctx: CanvasRenderingContext2D) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    drawMeasurement();
  }, []);

  const drawMeasurement = React.useCallback(() => {
    if (!measuringToolActive) {
      return;
    }
    if (!startPoint || !endPoint) {
      return;
    }

    const ctx = canvasRef.current?.getContext("2d");
    if (!ctx) {
      return;
    }

    // Calculate distance in pixels
    const pixelDistance = Math.sqrt(
      Math.pow(endPoint.x - startPoint.x, 2) +
        Math.pow(endPoint.y - startPoint.y, 2)
    );

    // Convert to feet
    const distanceInFeet = `${round(pixelDistance / pixelsToFeetRatio, 1)} ft`;

    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    // Draw the line between the two points
    handleDrawLineWithTicksAndDistance(
      ctx,
      startPoint.x,
      startPoint.y,
      endPoint.x,
      endPoint.y,
      distanceInFeet,
      isShiftPressed
    );
  }, [startPoint, endPoint, measuringToolState, isShiftPressed]);

  return (
    <ClickAwayListener
      onClickAway={() => {
        clearMeasurements();
        if (measuringToolState > 0) {
          setMeasuringToolState(0);
          setMeasuringToolActive(false);
        }
      }}
    >
      <ClearCanvasOverlay
        active={measuringToolActive}
        ref={canvasRef}
        expandBy={overlayExpansionRatio}
        onClick={handleCanvasClick}
        onDraw={draw}
        onMouseMove={(e: React.MouseEvent<HTMLCanvasElement>) => {
          if (startPoint && measuringToolState < 2) {
            const rect = canvasRef.current!.getBoundingClientRect();
            const x = e.clientX - rect.left;
            const y = e.clientY - rect.top;
            setEndPoint({ x, y });
          }
          if (startPoint && endPoint) {
            drawMeasurement();
          }
        }}
        style={{
          cursor: "crosshair",
        }}
      >
        {children}
      </ClearCanvasOverlay>
    </ClickAwayListener>
  );
};
