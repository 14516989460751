import { Box, Chip, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { format as formatDate, formatDistance } from "date-fns";
import * as React from "react";
import { Healthcheck } from "./WorkerStatus";

export const WorkerStatusPresenter: React.FC<{
  healthchecks: Healthcheck[];
}> = ({ healthchecks }) => {
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Worker",
      width: 200,
      editable: false,
      sortable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 125,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <Chip
            size="small"
            label={params.row.status}
            color={params.row.status === "OK" ? "success" : "error"}
          />
        );
      },
    },
    {
      field: "last_updated",
      headerName: "Last Checked",
      width: 250,
      editable: false,
      sortable: false,
      valueGetter: (_, row) => {
        if (!row.last_updated) {
          return "N/A";
        }
        return formatDate(
          new Date(row.last_updated),
          "yyyy-MM-dd hh:mm:ss a z"
        );
      },
    },
    {
      field: "last_updated_relative",
      headerName: "",
      width: 200,
      editable: false,
      sortable: false,
      valueGetter: (_, row) => {
        if (!row.last_updated) {
          return "N/A";
        }
        return formatDistance(new Date(row.last_updated), new Date(), {
          addSuffix: true,
        });
      },
    },
  ];
  return (
    <Box sx={{ height: "250px", width: "100%" }}>
      <Typography variant="subtitle2">Workers</Typography>
      <DataGrid
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableRowSelectionOnClick
        hideFooterPagination
        hideFooter
        rows={healthchecks}
        columns={columns}
        getRowId={(row) => row.name}
      />
    </Box>
  );
};
