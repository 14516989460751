import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { round } from "lodash";
import * as React from "react";
import { Ramp } from "../../../../types";
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
} from "../../../../widgets/ConfirmationDialog";
import { Toolbar } from "./Toolbar/Toolbar";

type Props = {
  ramp: Ramp;
  setRamp: (ramp: Ramp) => void;
  onClickSaveRamp: () => void;
  onClose: () => void;
  selectedTool: string;
  setSelectedTool: (tool: string) => void;
};

export const RampEditorForm: React.FC<Props> = ({
  ramp,
  setRamp,
  onClickSaveRamp,
  onClose,
  selectedTool,
  setSelectedTool,
}) => {
  const [confirmSave, setConfirmSave] = React.useState<ConfirmationDialogProps>(
    null
  );
  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Stack direction="column" spacing={2}>
        <TextField
          fullWidth
          size="small"
          label="Ramp Name"
          onChange={(evt) => {
            setRamp({
              ...ramp,
              name: evt.target.value,
            });
          }}
          value={ramp?.name ?? ""}
        />
        <Stack direction="column" spacing={1}>
          <TextField
            type="number"
            fullWidth
            disabled
            InputProps={{ inputProps: { min: 1, max: 10000 } }}
            size="small"
            label="Width (ft)"
            onChange={(evt) => {
              setRamp({
                ...ramp,
                width: parseFloat(evt.target.value),
              });
            }}
            value={round(ramp?.width, 0) ?? ""}
          />
          <TextField
            type="number"
            disabled
            fullWidth
            InputProps={{ inputProps: { min: 1, max: 10000 } }}
            size="small"
            label="Depth (ft)"
            onChange={(evt) => {
              setRamp({
                ...ramp,
                depth: parseFloat(evt.target.value),
              });
            }}
            value={round(ramp?.depth, 0) ?? ""}
          />
        </Stack>
        <Stack direction="column" spacing={1}>
          {ramp.markings.map((marking, idx) => (
            <Stack
              key={`marking-${idx}`}
              direction="row"
              spacing={1}
              alignItems="center"
              onMouseEnter={() => {
                setRamp({
                  ...ramp,
                  markings: ramp.markings.map((m) => ({
                    ...m,
                    selected: m.id === marking.id,
                  })),
                });
              }}
              onMouseLeave={() => {
                setRamp({
                  ...ramp,
                  markings: ramp.markings.map((m) => ({
                    ...m,
                    selected: false,
                  })),
                });
              }}
            >
              <Typography>{marking.type}</Typography>
              <IconButton
                onClick={() => {
                  setRamp({
                    ...ramp,
                    markings: ramp.markings.filter((m, i) => i !== idx),
                  });
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Stack>
          ))}
        </Stack>
        <Toolbar
          selectedTool={selectedTool}
          setSelectedTool={setSelectedTool}
        />
        <Stack direction="row" spacing={1} alignItems="flex-start">
          <Button
            fullWidth
            size="small"
            variant="contained"
            color="inherit"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            fullWidth
            size="small"
            variant="contained"
            color="success"
            onClick={() => {
              setConfirmSave({
                title: "Save Changes?",
                text: "Are you sure you want to save these changes?",
                onCancel: () => setConfirmSave(null),
                onClose: () => setConfirmSave(null),
                okText: "Save",
                onOk: () => {
                  onClickSaveRamp();
                  setConfirmSave(null);
                },
              });
            }}
          >
            Save
          </Button>
        </Stack>
      </Stack>
      {Boolean(confirmSave) && <ConfirmationDialog {...confirmSave} />}
    </>
  );
};
