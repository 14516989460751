import { Box, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { format as formatDate, formatDistance } from "date-fns";
import * as React from "react";
import { ExportChecksum } from "./AircraftLastUpdated";

export const AircraftLastUpdatedPresenter: React.FC<{
  exportChecksums: ExportChecksum[];
}> = ({ exportChecksums }) => {
  const columns: GridColDef[] = [
    {
      field: "table_name",
      headerName: "Table Name",
      width: 200,
      editable: false,
      sortable: false,
    },
    {
      field: "checksum",
      headerName: "Checksum",
      width: 300,
      editable: false,
      sortable: false,
    },
    {
      field: "last_updated",
      headerName: "Last Updated",
      width: 250,
      editable: false,
      sortable: false,
      valueGetter: (_, row) => {
        if (!row.last_updated) {
          return "N/A";
        }
        return formatDate(
          new Date(row.last_updated),
          "yyyy-MM-dd hh:mm:ss a z"
        );
      },
    },
    {
      field: "last_updated_relative",
      headerName: "",
      width: 200,
      editable: false,
      sortable: false,
      valueGetter: (_, row) => {
        if (!row.last_updated) {
          return "N/A";
        }
        return formatDistance(new Date(row.last_updated), new Date(), {
          addSuffix: true,
        });
      },
    },
  ];
  return (
    <Box sx={{ height: "250px", width: "100%" }}>
      <Typography variant="subtitle2">Aircraft Last Updated</Typography>
      <DataGrid
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableRowSelectionOnClick
        hideFooterPagination
        getRowId={(row) => row.table_name}
        rows={exportChecksums}
        columns={columns}
      />
    </Box>
  );
};
